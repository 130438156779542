import React from 'react';
import '../react-table.css';
import Select from 'react-select';
import styled from 'styled-components';
import Markdown from 'react-remarkable';
import apollo from '../lib/apollo';
import EditableTable from './EditableTable';
import { omit } from 'lodash';
import AllQuestionsQuery from '../graph/queries/AllQuestionsQuery';
import UpdateQuestionMutation from '../graph/mutations/UpdateQuestionMutation';
import DeleteQuestionMutation from '../graph/mutations/DeleteQuestionMutation';

const LongTextCell = styled.div`
  width: 100%;
  max-height: 120px;
  overflow: auto;
  white-space: normal;
  text-align: left;
  background: rgba(255, 255, 255, 0.3);
  padding: 14px;
  border: 1px solid #eee;
  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;
const MedTextCell = styled.div`
  width: 100%;
  overflow: auto;
  white-space: normal;
  background: rgba(255, 255, 255, 0.3);
`;
const viewOptions = [
  { value: 'all', label: 'Show All', filter: { status: { isNull: false } } },
  {
    value: 'unreview',
    label: 'Unreviewed',
    filter: { status: { equalTo: 'not-reviewed' } },
  },
  {
    value: 'reviewed',
    label: 'Reviewed',
    filter: { status: { equalTo: 'reviewed' } },
  },
];

const statusOptions = [
  { value: 'reviewed', label: 'Reviewed' },
  { value: 'not-reviewed', label: 'Not Reviewed' },
];

export default class QuestionsPage extends React.Component {
  state = {
    editing: false,
    view: 'all',
    filter: { filter: { status: { isNull: false } } },
  };
  columns = [
    {
      Header: 'Question',
      id: 'question',
      accessor: d => d.question,
      Cell: ({ column, original }) => (
        <LongTextCell>
          <Markdown>{column.accessor(original)}</Markdown>
        </LongTextCell>
      ),
      width: 400,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Path',
      id: 'uri',
      accessor: d => d.uri,
      Cell: ({ column, original }) => (
        <MedTextCell>
          <Markdown>{column.accessor(original)}</Markdown>
        </MedTextCell>
      ),
      width: 300,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Status',
      id: 'status',
      width: 126,
      accessor: ({ status }) =>
        status === 'not-reviewed' ? 'Not Reviewed' : 'Reviewed',
      editAccessor: d => statusOptions.find(({ value }) => value === d.status),
      EditWith: Select,
      editProps: {
        options: statusOptions,
        styles: {
          control: provided => ({
            ...provided,
            width: 140,
          }),
        },
      },
      style: {
        textAlign: 'center',
      },
    },
  ];
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
  save = async changes => {
    try {
      await apollo.mutate({
        mutation: UpdateQuestionMutation,
        variables: {
          input: { id: changes.id, questionPatch: omit(changes, ['id']) },
        },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  changeFilter = ({ value, filter }) => {
    this.setState({
      filter: filter ? { filter: { ...filter } } : null,
      view: value,
    });
  };
  delete = async ({ id }) => {
    try {
      await apollo.mutate({
        mutation: DeleteQuestionMutation,
        variables: { input: { id: id } },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  render() {
    return (
      <>
        <h1>
          Questions
          <Select
            className="select"
            width="200px"
            options={viewOptions}
            onChange={this.changeFilter}
            value={viewOptions.find(({ value }) => value === this.state.view)}
          />
        </h1>
        <EditableTable
          ref={this.table}
          queryName="allQuestions"
          save={this.save}
          query={AllQuestionsQuery}
          variables={this.state.filter}
          columns={this.columns}
          className="center-cells"
          delete={this.delete}
        />
      </>
    );
  }
}
