import React from 'react';
import '../react-table.css';
import Select from 'react-select';
import Input from '../components/Input';
import apollo from '../lib/apollo';
import EditableTable from './EditableTable';
import AllAffiliateLinksQuery from '../graph/queries/AllAffiliateLinksQuery';
import Button from '../components/Button';
import CompanySelector from '../components/CompanySelector';
import UpdateLinkMutation from '../graph/mutations/UpdateLinkMutation';
import CreateLinkMutation from '../graph/mutations/CreateLinkMutation';
import { omit } from 'lodash';
import DeleteLinkMutation from '../graph/mutations/DeleteLinkMutation';

const statusOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];

export default class PhonePage extends React.Component {
  state = {
    editing: false,
  };
  columns = [
    {
      editable: true,
      Header: 'Company',
      id: 'companyId',
      accessor: d => d.companyByCompanyId.name,
      editAccessor: d => d.companyId,
      width: 250,
      EditWith: CompanySelector,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Destination',
      id: 'destination',
      accessor: d => d.destination,
      width: 500,
      EditWith: Input,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Name',
      id: 'name',
      accessor: d => d.name,
      width: 120,
      EditWith: Input,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Status',
      id: 'active',
      width: 120,
      accessor: ({ active }) => (active ? 'Active' : 'Inactive'),
      editAccessor: d => statusOptions.find(({ value }) => value === d.active),
      EditWith: Select,
      editProps: { options: statusOptions },
      style: {
        textAlign: 'center',
      },
    },
  ];
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
  save = async changes => {
    try {
      await apollo.mutate({
        mutation: changes.id === 0 ? CreateLinkMutation : UpdateLinkMutation,
        variables:
          changes.id === 0
            ? { input: { link: omit(changes, ['id']) } }
            : { input: { id: changes.id, linkPatch: omit(changes, ['id']) } },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  delete = async ({ id }) => {
    try {
      await apollo.mutate({
        mutation: DeleteLinkMutation,
        variables: { input: { id: id } },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  render() {
    return (
      <>
        <h1>
          Affiliate Links
          <Button
            onClick={() =>
              this.table.current.newRow({
                phone: '',
                name: '',
                companyId: '',
                active: false,
                companyByCompanyId: { slug: '' },
              })
            }
          >
            Add Affiliate Link
          </Button>
        </h1>
        <EditableTable
          ref={this.table}
          queryName="allLinks"
          save={this.save}
          delete={this.delete}
          query={AllAffiliateLinksQuery}
          columns={this.columns}
        />
      </>
    );
  }
}
