import React from 'react';
import '../react-table.css';
import phoneFormatter from 'phone-formatter';
import Select from 'react-select';
import { omit } from 'lodash';
import Input from '../components/Input';
import AllPhonesQuery from '../graph/queries/AllPhonesQuery';
import apollo from '../lib/apollo';
import UpdatePhoneMutation from '../graph/mutations/UpdatePhoneMutation';
import EditableTable from './EditableTable';
import Button from '../components/Button';
import CreatePhoneMutation from '../graph/mutations/CreatePhoneMutation';
import CompanySelector from '../components/CompanySelector';
import DeletePhoneMutation from '../graph/mutations/DeletePhoneMutation';

const statusOptions = [
  { value: true, label: 'Active' },
  { value: false, label: 'Inactive' },
];

export default class PhonePage extends React.Component {
  state = {
    editing: false,
  };
  columns = [
    {
      editable: true,
      Header: 'Company',
      id: 'companyId',
      accessor: d => d.companyByCompanyId.name,
      editAccessor: d => d.companyId,
      width: 260,
      EditWith: CompanySelector,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Phone',
      id: 'phone',
      editAccessor: d => d.phone,
      accessor: d => `1${phoneFormatter.format(d.phone, '-NNN-NNN-NNNN')}`,
      width: 170,
      EditWith: Input,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Name',
      id: 'name',
      accessor: d => d.name,
      width: 170,
      EditWith: Input,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Status',
      id: 'active',
      width: 170,
      accessor: ({ active }) => (active ? 'Active' : 'Inactive'),
      editAccessor: d => statusOptions.find(({ value }) => value === d.active),
      EditWith: Select,
      editProps: { options: statusOptions },
      style: {
        textAlign: 'center',
      },
    },
  ];
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
  save = async changes => {
    try {
      await apollo.mutate({
        mutation: changes.id === 0 ? CreatePhoneMutation : UpdatePhoneMutation,
        variables:
          changes.id === 0
            ? { input: { phone: omit(changes, ['id']) } }
            : { input: { id: changes.id, phonePatch: omit(changes, ['id']) } },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  delete = async ({ id }) => {
    try {
      await apollo.mutate({
        mutation: DeletePhoneMutation,
        variables: { input: { id: id } },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  render() {
    return (
      <>
        <h1>
          Phones{' '}
          <Button
            onClick={() =>
              this.table.current.newRow({
                phone: '',
                name: '',
                companyId: '',
                active: false,
                companyByCompanyId: { slug: '' },
              })
            }
          >
            Add Phone
          </Button>
        </h1>
        <EditableTable
          ref={this.table}
          queryName="allPhones"
          save={this.save}
          delete={this.delete}
          query={AllPhonesQuery}
          columns={this.columns}
        />
      </>
    );
  }
}
