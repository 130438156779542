import gql from 'graphql-tag';
export default gql`
  mutation CreateTextblock($input: CreateTextblockInput!) {
    createTextblock(input: $input) {
      clientMutationId
      textblock {
        id
      }
    }
  }
`;
