import React from 'react';
import { Query } from 'react-apollo';
import ReactTable from 'react-table';
import '../react-table.css';
import { concat } from 'lodash';
import unpackEdge from '../lib/unpackEdge';

/*
 * ReactTable wrapper that allows us to drop in a
 * GraphQL query to load and display
 */

export default class Table extends React.Component {
  state = {
    sorted: [],
    page: 0,
    pageSize: 100,
    resized: [],
    filtered: [],
    editing: false,
  };

  render() {
    const { state } = this;
    const { query, columns, newRow, className, variables } = this.props;
    return (
      <Query
        query={query}
        variables={variables}
        notifyOnNetworkStatusChange={true}
      >
        {({ loading, error, data, refetch }) => {
          if (!this.refetch) this.refetch = refetch;
          const rows = unpackEdge(data, this.props.queryName);
          return (
            <ReactTable
              loading={loading}
              data={newRow ? concat(newRow, rows) : rows}
              columns={columns}
              defaultPageSize={100}
              pageSizeOptions={[5, 10, 20, 25, 50, 100]}
              className={`-striped -highlight ${className} ok`}
              // Controlled props
              sorted={state.sorted}
              page={state.page}
              pageSize={state.pageSize}
              resized={state.resized}
              filtered={state.filtered}
              // Callbacks
              onSortedChange={sorted => this.setState({ sorted })}
              onPageChange={page => this.setState({ page })}
              onPageSizeChange={(pageSize, page) =>
                this.setState({ page, pageSize })
              }
              onExpandedChange={expanded => this.setState({ expanded })}
              onResizedChange={resized => this.setState({ resized })}
              onFilteredChange={filtered => this.setState({ filtered })}
            />
          );
        }}
      </Query>
    );
  }
}
