import gql from 'graphql-tag';
export default gql`
  query {
    allPhones {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          phone
          companyId
          name
          createdAt
          active
          companyByCompanyId {
            name
            slug
            id
          }
        }
      }
    }
  }
`;
