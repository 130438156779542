import React from 'react';
import '../react-table.css';
import Select from 'react-select';
import styled from 'styled-components';
import Markdown from 'react-remarkable';
import Input from '../components/Input';
import apollo from '../lib/apollo';
import EditableTable from './EditableTable';
import { omit } from 'lodash';
import AllContactsQuery from '../graph/queries/AllContactsQuery';
import UpdateContactMutation from '../graph/mutations/UpdateContactMutation';
import DeleteContactMutation from '../graph/mutations/DeleteContactMutation';

const LongTextCell = styled.div`
  width: 100%;
  max-height: 120px;
  overflow: auto;
  white-space: normal;
  text-align: left;
  background: rgba(255, 255, 255, 0.3);
  padding: 14px;
  border: 1px solid #eee;
  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;
const MedTextCell = styled.div`
  width: 100%;
  overflow: visible;
  white-space: normal;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #eee;
`;
const viewOptions = [
  { value: 'all', label: 'Show All', filter: { readAt: { isNull: false } } },
  {
    value: 'unread',
    label: 'Unread',
    filter: { readAt: { isNull: true } },
  },
  {
    value: 'read',
    label: 'Read',
    filter: { readAt: { greaterThan: '0000-00-00 00:00:00.000000+00' } },
  },
];

const statusOptions = [
  { value: 'reviewed', label: 'Reviewed' },
  { value: 'not-reviewed', label: 'Not Reviewed' },
];

export default class ContactsPage extends React.Component {
  state = {
    editing: false,
    view: 'all',
    filter: { filter: { readAt: { isNull: false } } },
  };
  columns = [
    {
      Header: 'Message',
      id: 'message',
      accessor: d => d.message,
      Cell: ({ column, original }) => (
        <LongTextCell>{column.accessor(original)}</LongTextCell>
      ),
      width: 400,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: d => d.name,
      width: 150,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Email',
      id: 'email',
      accessor: d => d.email,
      width: 260,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Reason',
      id: 'reasion',
      accessor: d => d.reason,
      width: 80,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Read',
      id: 'read',
      width: 80,
      accessor: ({ readAt }) => (readAt ? 'Read' : 'Unread'),
      editAccessor: d => statusOptions.find(({ value }) => value === d.status),
      EditWith: Select,
      editProps: {
        options: statusOptions,
        styles: {
          control: provided => ({
            ...provided,
            width: 140,
          }),
        },
      },
      style: {
        textAlign: 'center',
      },
    },
  ];
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
  save = async changes => {
    try {
      await apollo.mutate({
        mutation: UpdateContactMutation,
        variables: {
          input: { id: changes.id, questionPatch: omit(changes, ['id']) },
        },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  changeFilter = ({ value, filter }) => {
    this.setState({
      filter: filter ? { filter: { ...filter } } : null,
      view: value,
    });
  };
  delete = async ({ id }) => {
    try {
      await apollo.mutate({
        mutation: DeleteContactMutation,
        variables: { input: { id: id } },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  render() {
    return (
      <>
        <h1>
          Contact Submissions
          <Select
            className="select"
            width="200px"
            options={viewOptions}
            onChange={this.changeFilter}
            value={viewOptions.find(({ value }) => value === this.state.view)}
          />
        </h1>
        <EditableTable
          ref={this.table}
          queryName="allContacts"
          save={this.save}
          query={AllContactsQuery}
          variables={this.state.filter}
          columns={this.columns}
          className="center-cells"
          delete={this.delete}
        />
      </>
    );
  }
}
