import React from 'react';
import ReactLoading from 'react-loading';
import { Colors } from '../constants/Style';

export default () => {
  return (
    <div style={{ margin: '60px' }}>
      <ReactLoading type="spin" color={Colors.darkBlueGray} />
    </div>
  );
};
