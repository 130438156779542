import React from 'react';
import styled from 'styled-components';
import TextareaAutosize from 'react-textarea-autosize';
import { isNumber, pullAt } from 'lodash';
import { Colors } from '../constants/Style';
import Button from './Button';
import { lighten } from 'polished';
import insert from '../lib/insert';
import Modal from 'react-modal';

const Shell = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-right: -3.68rem;
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
    tr.removing {
      td {
        background: ${Colors.warnBg};
      }
      td {
        border-width: 1px;
      }
    }
    td {
      min-height: 12.5rem;
      border: 2px solid ${Colors.veryLightBlueGray};
      position: relative;
      padding: 4px;
      /* width: 120px; */
      textarea {
        width: 100%;
        height: 100%;
        border: 0;
        resize: none;
        padding: 0.4rem;
        font-size: 0.94rem;
        font-weight: bold;
        color: ${Colors.darkBlueGray};
        font-family: 'Courier New', Courier, monospace;
        max-height: 240px;
      }
      &.removing {
        background: ${Colors.warnBg} !important;
        textarea {
          background-image: ${Colors.warnBg} !important;
        }
        textarea {
          background: transparent;
        }
      }
      &.header-cell {
        background: #fcff741a;
        textarea {
          font-weight: bold;
          font-size: 11pt;
          color: #627175;
        }
      }
      &.active {
        background: rgba(0, 120, 200, 0.04);
        /* width: 220px; */
        /* max-width: 200px; */
        opacity: 1;
      }
      textarea {
        padding: 0;
        background: transparent;
      }
      textarea {
        background: transparent;
      }
    }
    th {
      position: relative;
      padding-bottom: 9px;
      height: 30px;
      /* min-width: 41px; */
    }
  }
`;

const OptionShell = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: ${({ zIndex }) => zIndex};
  top: 6px;
  ${Button} {
    font-size: 0.7rem;
    opacity: 0.7;
    width: 34px;
  }

  ul {
    position: absolute;
    z-index: 15;
    top: 23px;
    left: ${({ axis }) => (axis === 'x' ? null : '0')};
    right: ${({ axis }) => (axis === 'x' ? '16px' : '0')};
    background: #fff;
    border-radius: 4px;
    box-shadow: 1px 1px 1px rgba(206, 215, 218, 0.55);
    margin: 0 auto;
    padding: 0;
    width: 190px;
    border: 1px solid #e2eff3;
    overflow: hidden;
    li {
      list-style-type: none;
      button {
        border: 0;
        padding: 10px 14px;
        background: #fff;
        width: 100%;
        text-align: left;
        font-weight: 600;
        color: ${Colors.darkBlueGray};
        transition: 0.15s all;
        cursor: pointer;
        &:hover {
          background: ${lighten(0.033, Colors.whiteBlue)};
        }
      }
    }
  }
`;

const Toolbar = styled.div`
  color: ${lighten(0.34, Colors.blueGray)};
  margin-bottom: 1.1rem;
  button {
    border: 1px solid #ced7da;
    border-radius: 3px;
    margin-right: 4px;
    background: #fff;
    text-decoration: none;
    padding: 4px 10px 3px;
    position: relative;
    color: ${({ mode }) =>
      mode === 'mild-danger' ? Colors.mildRed : Colors.darkBlueGray};
    font-size: 13px;
    cursor: pointer;
    transition: 0.24s all;
    font-weight: 600;
    font-family: sans-serif;
    box-shadow: 1px 1px 1px rgba(206, 215, 218, 0.55);
  }
`;
const RightControl = styled.th`
  width: 70px;
`;

const Options = ({
  x = false,
  y = false,
  optionsOpen,
  openAxis,
  toggleOptions,
  toggleHeader,
  isHeader,
  add,
  remove,
  removing,
}) => {
  const axis = isNumber(x) ? 'x' : 'y';
  const vals = {
    x: {
      type: 'row',
      name: 'Row',
      obj: { x },
    },
    y: {
      type: 'col',
      name: 'Column',
      obj: { y },
    },
  };
  const { name, obj } = vals[axis];
  return (
    <OptionShell axis={axis}>
      <Button className="small" onClick={toggleOptions}>
        {optionsOpen && openAxis === axis ? '×' : '●●●'}
      </Button>
      {optionsOpen && openAxis === axis && (
        <ul>
          {removing && removing.hasOwnProperty(axis) ? (
            <li>
              <button type="button" onClick={remove.bind(null, obj)}>
                Click Again to Delete
              </button>
              <button type="button" onClick={toggleOptions}>
                Cancel
              </button>
            </li>
          ) : (
            <>
              <li>
                <button
                  type="button"
                  onClick={() => toggleHeader({ x, y, isHeader })}
                >
                  {isHeader ? `Make Normal ${name}` : `Make Header ${name} `}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={add.bind(null, { x, y, axis, dir: -1 })}
                >
                  {`Add ${name} Before`}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  onClick={add.bind(null, { x, y, axis, dir: 1 })}
                >
                  {`Add ${name} After`}
                </button>
              </li>
              <li>
                <button type="button" onClick={remove.bind(null, obj)}>
                  {`Delete this ${name}`}
                </button>
              </li>
            </>
          )}
        </ul>
      )}
    </OptionShell>
  );
};

export const baseCell = { content: '', style: {} };
export const genCells = num => {
  const row = [];
  for (let y = 0; y < num; y += 1) {
    row.push({ ...baseCell });
  }
  return row;
};
export const genRows = (numRows, numCols) => {
  const rows = [];
  for (let x = 0; x < numRows; x += 1) {
    const row = genCells(numCols);
    rows.push(row);
  }
  return rows;
};

export class TableBuilder extends React.Component {
  editors = {};
  baseCell = { content: '', style: {} };
  state = {
    data: [],
    merging: false,
    meta: '',
    active: '0,0',
    optionsOpen: false,
    removing: false,
    mainKey: 'u9812',
  };
  handleContentChange = ({ currentTarget }) => {
    const name = currentTarget.name;
    const [x, y] = name.split(',');
    const { data, meta } = this.props.value;
    const rows = [...data];
    rows[x][y].content = currentTarget.value;
    this.props.onChange(name, { meta, data: rows });
  };
  setActive = e => {
    if (e.currentTarget) {
      window.editor = e.currentTarget;
      this.setState({
        active: e.currentTarget.name,
        optionsOpen: false,
        editor: e.currentTarget,
      });
    }
  };
  toggleOptions = openAxis => {
    this.setState({
      optionsOpen: !this.state.optionsOpen,
      openAxis,
      removing: false,
    });
  };
  add = ({ x, y, dir, axis }) => {
    const { data } = this.props.value;
    let out = [];
    if (axis === 'x') {
      const row = genCells(data[0].length).map((cell, cellY) => {
        if (this.isHeader({ y: cellY })) {
          cell.header = true;
        }
        return cell;
      });
      out = insert(x + dir, row, [...data]);
    } else if (axis === 'y') {
      out = data.map((row, cellX) =>
        insert(
          y + (dir < 0 ? 0 : 1),
          { ...baseCell, header: this.isHeader({ x: cellX }) },
          [...row],
        ),
      );
    }
    this.props.onChange(this.props.name, { meta: this.props.meta, data: out });
    this.setState({ optionsOpen: false });
    this.forceRerender();
  };
  isRemoving = ({ x, y }) => {
    const {
      removing: { x: rX, y: rY },
    } = this.state;
    if (isNumber(x) && isNumber(rX) && x === rX) {
      return true;
    }
    if (isNumber(y) && isNumber(rY) && y === rY) {
      return true;
    }
    return false;
  };
  startRemove = removing => {
    if (this.state.removing) {
      this.remove(removing);
    } else {
      this.setState({ removing });
    }
  };
  remove = ({ x, y }) => {
    const { data } = this.props.value;
    let out = [...data];
    if (isNumber(x)) {
      pullAt(out, [x]);
    }
    if (isNumber(y)) {
      out = data.map(row => {
        pullAt(row, [y]);
        return row;
      });
    }
    this.handleChange({ data: [...out] });
    this.setState({ optionsOpen: false, removing: false });
  };
  handleChange = changes => {
    this.props.onChange(this.props.name, {
      meta: this.props.value.meta,
      data: this.props.value.data,
      ...changes,
    });
    this.forceRerender();
  };
  forceRerender = () => {
    this.setState({ mainKey: `mainKey-${+new Date()}` });
  };
  command = cmd => {
    this.editors[this.state.active].execCommand(cmd);
  };
  rowLength = row => {
    return row.reduce((len, col) => {
      len += +col.colspan || 1;
      return len;
    }, 0);
  };
  mergeStart = () => {
    this.merge(1);
  };
  merge = numCols => {
    // Prevent accidental double merging
    if (!this.state.merging) {
      const { data } = this.props.value;
      const [x, y] = this.state.active.split(',');
      const row = data[x];
      this.setState({ merging: true });
      const maxLen = this.rowLength(row);
      const thisCol = data[x][y];
      if (+y + +thisCol.colspan + +numCols > maxLen) {
        numCols = maxLen - thisCol.colspan - y;
      }
      data[x] = row.reduce((out, col, i) => {
        if (+i === +y) {
          col.colspan = col.colspan ? col.colspan + numCols : 1 + numCols;
          if (col.colspan + y - 1 > maxLen) {
            col.colpan = maxLen - y - 1;
          }
        }
        if (+i <= +y || +y + +numCols < i) {
          out.push(col);
        } else {
          out[y].content += ` ${col.content}`;
        }
        return out;
      }, []);
      this.handleChange({ data: [...data] });
      setTimeout(() => {
        this.setState({ merging: false });
      }, 800);
    }
  };
  toggleHeader = ({ x, y, isHeader }) => {
    let data = [...this.props.value.data];
    if (isNumber(x)) {
      data[x] = data[x].map((row, y) => ({
        ...row,
        header: this.isHeader({ y }) || !isHeader,
      }));
    } else if (isNumber(y)) {
      data = data.map((row, x) => {
        row[y] = {
          ...row[y],
          header: this.isHeader({ x }) || !isHeader,
        };
        return row;
      });
    }
    this.handleChange({ data, optionsHeader: false });
  };
  isHeader = ({ x, y }) => {
    const { data } = this.props.value;
    let isHeader = true;
    if (isNumber(x)) {
      data[x].forEach(({ header }) => {
        if (!header) {
          isHeader = false;
        }
      });
    }
    if (isNumber(y)) {
      data.forEach(row => {
        if (row[y] && !row[y].header) {
          isHeader = false;
        }
      });
    }
    return isHeader;
  };
  passFocus = e => {
    e.currentTarget.querySelector('textarea').focus();
  };
  shortcode = code => {
    const { editor } = this.state;
    // const editor = this.editors[this.state.active];
    // const cm = editor.codemirror;
    let text = '';
    let fields = {};
    // window.editor = editor;
    // var doc = editor.codemirror.doc;
    // var cursor = doc.getCursor();
    const start = editor.selectionStart;
    const end = editor.selectionEnd;
    const value = editor.value;
    const selText = value.substr(start, end - start);
    // let selRange = {};
    // if (selText.length) {
    //   selRange = cm.listSelections();
    // }
    switch (code) {
      case 'link': {
        text = `[link to="${selText}"]${selText}[/link]`;
        break;
      }
      case 'button': {
        text = `[button to="${selText}"]${selText}[/button]`;
        break;
      }
      case 'image': {
        text = `[img]${`${selText.length ? selText : ''}`}[/img]`;
        break;
      }
      case 'underline': {
        text = `<u>${selText}</u>`;
        break;
      }
      case 'check': {
        text = `[check]`;
        break;
      }
      case 'cta': {
        fields = {
          name: { type: 'text', val: '' },
          slug: { type: 'text', val: '' },
        };
        text = `[cta name="%name%" slug="%slug%"]`;
        break;
      }
      case 'company': {
        fields = {
          field: { type: 'text', val: '' },
          slug: { type: 'text', val: '' },
        };
        text = `[company slug="%slug%" field="%field%"]`;
        break;
      }
      case 'logo': {
        fields = {
          slug: { type: 'text', val: '' },
        };
        text = `[logo slug="%slug%"]`;
        break;
      }
      case 'x': {
        text = `[x]`;
        break;
      }
      default: {
      }
    }
    const processed = Object.entries(fields).reduce(
      (out, [key, { type, val }]) => {
        out = out.replace(`%${key}%`, val);
        return out;
      },
      text,
    );

    editor.value = `${value.substr(0, start)}${processed}${value.substr(end)}`;
    this.handleContentChange({ currentTarget: editor });
  };
  render() {
    const { data } = this.props.value;
    const { active, optionsOpen, openAxis } = this.state;
    return (
      <Shell>
        <Toolbar>
          <button type="button" onClick={() => this.command('bold')}>
            Bold
          </button>
          <button type="button" onClick={() => this.command('italic')}>
            Italic
          </button>
          <button type="button" onClick={() => this.shortcode('underline')}>
            Underline
          </button>
          &nbsp;|&nbsp;&nbsp;
          <button type="button" onClick={() => this.shortcode('link')}>
            Link
          </button>
          <button type="button" onClick={() => this.shortcode('button')}>
            Button
          </button>
          <button type="button" onClick={() => this.shortcode('image')}>
            Image
          </button>
          &nbsp;|&nbsp;&nbsp;
          <button type="button" onClick={() => this.shortcode('check')}>
            Check
          </button>
          <button type="button" onClick={() => this.shortcode('x')}>
            X
          </button>
          &nbsp;|&nbsp;&nbsp;
          <button type="button" onClick={() => this.shortcode('company')}>
            Company
          </button>
          <button type="button" onClick={() => this.shortcode('logo')}>
            Logo
          </button>
          <button type="button" onClick={() => this.shortcode('cta')}>
            CTA
          </button>
          &nbsp;|&nbsp;&nbsp;
          <button type="button" onClick={() => this.mergeStart()}>
            Merge Right
          </button>
        </Toolbar>
        <main key={this.state.mainKey}>
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              {!data && (
                <tr>
                  <td style={{ padding: '30px' }}>
                    Error: No table data or corrupted data. Contact an admin to
                    resolve in database
                  </td>
                </tr>
              )}
              {data &&
                data.map((cells, x) => (
                  <tr
                    key={x}
                    className={this.isRemoving({ x }) ? 'removing' : ''}
                  >
                    {cells.map((cell, y) => (
                      <td
                        key={y}
                        onClick={this.passFocus}
                        colSpan={cell.colspan || '1'}
                        className={`${
                          this.isRemoving({ y }) ? 'removing' : ''
                        } ${active === `${x},${y}` ? 'active' : ''}
                          ${cell.header ? 'header-cell' : ''}`}
                      >
                        <TextareaAutosize
                          onFocus={this.setActive}
                          name={`${x},${y}`}
                          key={`${x},${y}`}
                          data-gramm_editor="false"
                          onChange={this.handleContentChange}
                          placeholder="Cell Content"
                        >
                          {cell.content}
                        </TextareaAutosize>
                      </td>
                    ))}
                    <RightControl>
                      {`%${active}`.includes(`%${x},`) && (
                        <Options
                          x={x}
                          zIndex="13"
                          openAxis={openAxis}
                          optionsOpen={optionsOpen}
                          toggleOptions={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.toggleOptions('x');
                          }}
                          add={this.add}
                          remove={this.startRemove}
                          removing={this.state.removing}
                          toggleHeader={this.toggleHeader}
                          isHeader={this.isHeader({ x })}
                        />
                      )}
                    </RightControl>
                  </tr>
                ))}
              <tr>
                {data &&
                  data.length > 0 &&
                  data[this.state.active.split(',')[0]].map((cell, y) => (
                    <th key={`th-${y}`} colSpan={cell.colspan || '1'}>
                      {`${active}%`.includes(`,${y}%`) && (
                        <Options
                          y={y}
                          zIndex="12"
                          openAxis={openAxis}
                          optionsOpen={optionsOpen}
                          toggleOptions={e => {
                            e.stopPropagation();
                            e.preventDefault();
                            this.toggleOptions('y');
                          }}
                          toggleHeader={this.toggleHeader}
                          add={this.add}
                          remove={this.startRemove}
                          removing={this.state.removing}
                          isHeader={this.isHeader({ y })}
                        />
                      )}
                    </th>
                  ))}
                <th />
              </tr>
            </tbody>
          </table>
        </main>
      </Shell>
    );
  }
}
