import React from 'react';
import '../react-table.css';
import { Query } from 'react-apollo';
import Spinner from 'node-spintax';
import { mean } from 'lodash';
import apollo from '../lib/apollo';
import { Formik } from 'formik';
import UpdateTextblockMutation from '../graph/mutations/UpdateTextblockMutation';
import Textarea from '../components/Textarea';
import TextblockByIdQuery from '../graph/queries/TextblockByIdQuery';
import Loading from '../components/Loading';
import SpintaxArea from '../components/DynamicContentEditor';
import {
  FormRow,
  Form,
  Input,
  Button,
  Label,
  FormBox,
} from '../components/Form';

export default class CreateTextblockPage extends React.Component {
  calcTimo = 0;
  state = {
    variations: 0,
    count: 0,
    content: '',
    headline: '',
  };
  submit = async (
    { group, section, content, headline, context },
    { setSubmitting, setStatus },
  ) => {
    try {
      const rsp = await apollo.mutate({
        mutation: UpdateTextblockMutation,
        variables: {
          input: {
            id: +this.props.match.params.id,
            textblockPatch: {
              group,
              section,
              content,
              headline,
              context,
            },
          },
        },
      });
      setSubmitting(false);
      setStatus('success');
      setTimeout(() => {
        setStatus('ready');
      }, 5);
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  calcVals = content => {
    if (content !== this.state.content) {
      clearTimeout(this.calcTimo);
      this.calcTimo = setTimeout(() => {
        const spin = new Spinner(content);
        this.setState({
          variations: spin.countVariations(),
          count: Math.floor(
            mean(spin.unspin(true, 1000).map(v => v.split(' ').length)),
          ),
          content,
        });
      }, 500);
    }
  };
  render() {
    return (
      <Query
        query={TextblockByIdQuery}
        variables={{ id: +this.props.match.params.id }}
      >
        {({ loading, data, error }) => {
          if (error) return this.renderError(error);
          else if (loading) return <Loading />;
          else {
            const {
              section,
              group,
              content,
              headline,
              context,
            } = data.textblockById;
            return (
              <>
                <h1>Edit Textblock</h1>
                <Formik
                  initialValues={{
                    group,
                    section,
                    content,
                    headline,
                    context,
                  }}
                  onSubmit={this.submit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    submitForm,
                    isSubmitting,
                    status,
                  }) => {
                    const { content } = values;
                    this.calcVals(content);
                    return (
                      <Form onSubmit={handleSubmit} id="textblock-form">
                        <FormRow>
                          <FormBox>
                            <Label>Group</Label>
                            {errors.group && touched.group}
                            <Input
                              type="text"
                              name="group"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.group}
                            />
                          </FormBox>
                          <FormBox>
                            <Label>Section</Label>
                            <Input
                              type="text"
                              name="section"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.section}
                            />
                          </FormBox>
                        </FormRow>
                        <FormRow>
                          <FormBox>
                            <Label>Headline</Label>
                            <SpintaxArea
                              Component={Input}
                              name="headline"
                              value={values.headline || ''}
                              onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </FormBox>
                        </FormRow>
                        <FormRow>
                          <FormBox maxWidth="100%">
                            <Label>Content</Label>
                            <SpintaxArea
                              Component={Textarea}
                              style={{ width: '100%', minHeight: '180px' }}
                              name="content"
                              value={values.content}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              context={values.context}
                              editorContext={{
                                isSubmitting,
                                status,
                                submitForm,
                              }}
                              onContextChange={({ value }) =>
                                handleChange({
                                  target: { name: 'context', value },
                                })
                              }
                            />
                          </FormBox>
                        </FormRow>
                        <FormRow>
                          <FormBox>
                            <Button type="submit" disabled={isSubmitting}>
                              {isSubmitting
                                ? 'Saving...'
                                : status === 'success'
                                ? 'Saved!'
                                : 'Save'}
                            </Button>
                          </FormBox>
                        </FormRow>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            );
          }
        }}
      </Query>
    );
  }
}
