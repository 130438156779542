import { css } from 'styled-components';
import { lighten } from 'polished';
const colors = {
  white: '#fff',
  veryLightBlue: '#F4F9FF',
  lightBlue: '#7F9AB8',
  darkBlue: '#154F8F',
  lightBrightBlue: '#E1EDFC',
  darkBlueGray: '#5B6B7D',
  blueGray: '#5B6B7D',
  brown: '#898886',
  darkDrown: '#716E68',
  canvas: '#F1EDE1',
  veryLightGold: '#FFFCF3',
  gold: '#ECC537',
  green: '#499F41',
  greenGray: '#F3F4F2',
  whiteBlue: '#E6FDFF',
  mildRed: '#b65f52',
  warnBg:
    'repeating-linear-gradient( 45deg, #efe6e1, #efe6e1 10px, #e7dad1 10px, #e7dad1 20px )',
};

const { blueGray, darkBlue, darkBlueGray } = colors;
export const Colors = {
  medText: darkBlueGray,
  darkText: darkBlue,
  lightBlueGray: lighten(0.48, blueGray),
  veryLightBlueGray: lighten(0.52, blueGray),
  ...colors,
};

export const Dims = {
  container: '76rem',
  wideContainer: '86rem',
  tabletPadding: ' 0 3rem',
};

export const Fonts = {
  serif: `'Zilla Slab', serif`,
  sans: `'Chivo', sans-serif`,
};

export const media = {
  handxs: (...args) =>
    css`
      @media (max-width: 320px) {
        ${css(...args)};
      }
    `,
  handsm: (...args) =>
    css`
      @media (max-width: 375px) {
        ${css(...args)};
      }
    `,
  hand: (...args) =>
    css`
      @media (max-width: 415px) {
        ${css(...args)};
      }
    `,
  tablet: (...args) =>
    css`
      @media (max-width: 1238px) {
        ${css(...args)};
      }
    `,
  lapsm: (...args) =>
    css`
      @media (max-width: 1388px) {
        ${css(...args)};
      }
    `,
  lap: (...args) =>
    css`
      @media (max-width: 1441px) {
        ${css(...args)};
      }
    `,
  short: (...args) =>
    css`
      @media (max-height: 960px) {
        ${css(...args)};
      }
    `,
  xx: (...args) =>
    css`
      @media (min-resolution: 1.25dppx) {
        ${css(...args)};
      }
    `,
  xxx: (...args) =>
    css`
      @media (min-resolution: 2.25dppx) {
        ${css(...args)};
      }
    `,
};
