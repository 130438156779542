import gql from 'graphql-tag';
export default gql`
  query AllReviews($filter: ReviewFilter) {
    allReviews(filter: $filter) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          title
          body
          equipmentProtectionScore
          homeAutomationScore
          customerServiceScore
          ipaddress
          hiddenAt
          publishedAt
          companyId
          createdAt
          quality
          raterName
          baseQuality
          companyByCompanyId {
            name
            slug
            id
          }
          votesByReviewId {
            edges {
              node {
                useful
              }
            }
          }
        }
      }
    }
  }
`;
