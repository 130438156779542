import gql from 'graphql-tag';
export default gql`
  query AllNotFoundsQuery($filter: NotFoundFilter) {
    allNotFounds(first: 100, orderBy: ID_DESC, filter: $filter) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          path
          agent
          from
          status
          createdAt
          updatedAt
        }
      }
    }
  }
`;
