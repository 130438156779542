import React, { useState, useEffect } from 'react';
import { mean, uniq } from 'lodash';
import Spinner from 'node-spintax';
import Remarkable from 'react-remarkable';
import styled from 'styled-components';

const VarRow = styled.div.attrs({ className: 'variation-row' })`
  background: #f2f2f2;
  padding: 24px;
  box-sizing: border-box;
  margin: 10px 10px 10px 0;
`;

const Variations = ({ value, numVariations = 100 }) => {
  const [variations, setVariations] = useState([]);
  useEffect(() => {
    const spin = new Spinner(value);
    setVariations(uniq(spin.unspin(true, numVariations)));
  }, [value]);
  return (
    <>
      {variations.map(v => {
        return (
          <VarRow>
            <Remarkable>{v}</Remarkable>
          </VarRow>
        );
      })}
    </>
  );
};

export default Variations;
