import React from 'react';
import '../react-table.css';
import dayjs from 'dayjs';
import styled from 'styled-components';
import apollo from '../lib/apollo';
import BuildLogByIdQuery from '../graph/queries/BuildLogByIdQuery';
import { Query } from 'react-apollo';
import Loading from '../components/Loading';
import JSONPretty from 'react-json-pretty';
import SeoIssueTable from '../components/SEOIssueTable';

// const JSON = styled(JSONPretty)`
//   font-size: 0.8rem;
// `;

export default class BuildPage extends React.Component {
  renderError = error => {
    return <div>Error</div>;
  };
  render() {
    return (
      <Query
        query={BuildLogByIdQuery}
        variables={{ id: +this.props.match.params.id }}
      >
        {({ loading, data, error }) => {
          if (error) return this.renderError(error);
          else if (loading) return <Loading />;
          else {
            const {
              machine,
              timestamp,
              seo: seoStr,
              log,
              env,
            } = data.buildLogById;
            const timeStr = dayjs(timestamp)
              .subtract(new Date().getTimezoneOffset(), 'm')
              .format('MM-DD-YY [at] H:mm');
            const seo = window.JSON.parse(seoStr);
            return (
              <>
                <h1>{`Build on ${machine}`}</h1>
                <h4>{`${timeStr} | env: ${env}`}</h4>
                <h2>SEO Alerts</h2>
                <div style={{ width: '100%' }}>
                  <SeoIssueTable bySlug={seo.slugs} />
                </div>
                <h2>Build Log</h2>
                {/* <JSON data={log} /> */}
              </>
            );
          }
        }}
      </Query>
    );
  }
}
