import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Button from './Button';
import { Colors } from '../constants/Style';

const Shell = styled.div`
  margin-top: 1rem;
  padding: 1.4rem;
  background: #f1f6f8;
  color: ${Colors.darkBlueGray};
  h2 {
    margin-top: 0;
    font-size: 1.2rem;
    margin-bottom: 0.8rem;
    button {
      position: relative;
      top: -2px;
      left: 0.5rem;
    }
  }
  span {
    &.status {
      padding: 3px 6px;
      font-family: 'Courier New', Courier, monospace;
      font-weight: bold;
      font-size: 0.85rem;
      background: #d2e9f0;
      margin-right: 3px;
      position: relative;
      top: -2px;
    }
  }
`;

/*
 * Checks the latest netlify build status and allows
 * team to trigger a build
 */
export default () => {
  const [build, setBuild] = useState(false);
  const triggerBuild = async () => {
    await axios.post(
      // Found under "Build Hooks here: https://app.netlify.com/sites/harsite/settings/deploys"
      'https://api.netlify.com/build_hooks/5cdf39e8ddefa80182072913',
      {},
      { withCredentials: false, headers: { authorization: '' } },
    );
    window.location.reload();
  };
  useEffect(() => {
    const fetchStatus = async () => {
      const rsp = await axios.get(
        // API_ID here: https://app.netlify.com/sites/harsite/settings/general
        // https://open-api.netlify.com/#/default/listSiteDeploys
        'https://api.netlify.com/api/v1/sites/c439a9b3-843d-421c-8e56-0042215a5f7e/deploys?access_token=f0d7557391c85f67425df5b14611ec66bf8f25453dc3bd900b4c03ad24e4c8c5',
        { withCredentials: false, headers: { authorization: '' } },
      );
      setBuild(rsp.data[0]);
    };
    fetchStatus();
  }, []);
  return (
    <Shell>
      <h2>
        Build Status
        {build &&
          !['enqueued', 'building', 'processing', 'uploading'].includes(
            build.state,
          ) && <Button onClick={triggerBuild}>Trigger Build</Button>}
      </h2>
      <div>
        {build && (
          <div>
            <span>
              <b>Last build: </b>
            </span>
            <span className="status">{build.state}</span>
            <span>{build.title}</span>
            <span>
              {' '}
              - {dayjs(build.created_at).format('MM-DD-YY [at] HH:mm')}
            </span>
          </div>
        )}
        {!build && <div className="loading">Loading latest build...</div>}
      </div>
    </Shell>
  );
};
