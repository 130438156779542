import styled from 'styled-components';
import React from 'react';
import { lighten, darken } from 'polished';
import { Colors } from '../constants/Style';
import { NavLink } from 'react-router-dom';
import { AuthContext } from '../lib/AuthContextProvider';

const ColumnWrap = styled.div`
  display: flex;
  height: 100%;
  min-height: 100vh;
`;

const Sidebar = styled.div`
  width: 10rem;
  background: ${Colors.veryLightBlueGray};
  color: ${Colors.blueGray};
  flex: none;
  h2 {
    text-align: center;
    margin-bottom: 20px;
    background: ${Colors.lightBlueGray};
    height: 4.2rem;
    margin-top: 0;
    font-size: 1.1rem;
    text-transform: uppercase;
    line-height: 30px;
    padding: 1.2rem 0 1rem;
  }
  a {
    display: block;
    font-size: 1rem;
    text-decoration: none;
    padding: 10px 18px 11px;
    color: ${Colors.blueGray};
    font-weight: 600;
    transition: 0.15s all;
    &.active {
      background: ${lighten(0.015, Colors.lightBlueGray)};
      border-left: 4px solid ${lighten(0.3, Colors.blueGray)};
    }
    &:hover {
      background: ${darken(0.01, Colors.lightBlueGray)};
      color: ${darken(0.15, Colors.blueGray)};
    }
  }
  h5 {
    font-size: 0.9rem;
    text-transform: uppercase;
    padding-left: 1.2rem;
    margin-bottom: 0;
    font-weight: 800;
    opacity: 0.9;
    border-bottom: 1px solid #d9e2e6;
    padding-bottom: 0.6rem;
    color: #55779f;
    margin-top: 1.2rem;
  }
`;
const TopNav = styled.div`
  height: 4.2rem;
  background: ${Colors.veryLightBlueGray};
  border: 0;
  margin: 0 -3rem;
`;
const Content = styled.div`
  flex: 1;
  padding: 0 3rem;
  h1 {
    display: flex;
    font-size: 2.3rem;
    color: ${Colors.darkBlueGray};
    padding: 1.2rem 0rem 0.2rem;
    font-weight: 900;
    align-items: center;
    button,
    a {
      margin-left: 16px;
      margin-top: 0px;
    }
    .select {
      color: ${lighten(0.0, Colors.darkBlueGray)} !important;
      position: relative;
      box-sizing: border-box;
      width: 220px;
      font-size: 1.1rem;
      margin-left: 20px;
      font-weight: 600;
    }
  }
  h2 {
    font-size: 1.7rem;
    color: ${lighten(0.1, Colors.darkBlueGray)};
    margin-bottom: 0.4rem;
  }
  h4 {
    font-size: 1.2rem;
    color: ${lighten(0.1, Colors.darkBlueGray)};
    margin-top: -1.4rem;
    margin-bottom: 2rem;
  }
`;

export default ({ children }) => {
  return (
    <AuthContext.Consumer>
      {({ isAuthd, user }) => (
        <ColumnWrap>
          {isAuthd && (
            <Sidebar>
              <h2>HAR Admin</h2>
              {user.capabilities.includes('dash') && (
                <NavLink exact to="/">
                  Dashboard
                </NavLink>
              )}
              {user.capabilities.includes('content') && (
                <>
                  <h5>Content</h5>
                  <NavLink to="/companies">Companies</NavLink>
                  <NavLink to="/links">Affiliate Links</NavLink>
                  <NavLink to="/phones">Phones</NavLink>
                  <NavLink to="/textblocks">Textblocks</NavLink>
                  <NavLink to="/tables">Tables</NavLink>
                  <NavLink to="/cities">Cities</NavLink>
                </>
              )}
              {user.capabilities.includes('submissions') && (
                <>
                  <h5>Submissions</h5>
                  <NavLink to="/reviews">Reviews</NavLink>
                  <NavLink to="/questions">Questions</NavLink>
                  <NavLink to="/contacts">Contacts</NavLink>
                  <NavLink to="/404s">404s</NavLink>
                </>
              )}
              {user.capabilities.includes('data') && (
                <>
                  <h5>Crime Data</h5>
                  <NavLink to="/data">Docs</NavLink>
                </>
              )}
            </Sidebar>
          )}
          <Content>
            <TopNav />
            {children}
          </Content>
        </ColumnWrap>
      )}
    </AuthContext.Consumer>
  );
};
