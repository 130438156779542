import React from 'react';
import '../react-table.css';
import Select from 'react-select';
import { omit } from 'lodash';
import Input from '../components/Input';
import AllCompaniesQuery from '../graph/queries/AllCompaniesQuery';
import apollo from '../lib/apollo';
import UpdateCompanyMutation from '../graph/mutations/UpdateCompanyMutation';
import EditableTable from './EditableTable';
import Button from '../components/Button';
import CreateCompanyMutation from '../graph/mutations/CreateCompanyMutation';

const partnerOptions = [
  { value: true, label: 'Is Partner' },
  { value: false, label: 'Not Partner' },
];

export default class CompaniesPage extends React.Component {
  state = {
    editing: false,
  };
  columns = [
    {
      editable: true,
      Header: 'Company',
      id: 'name',
      accessor: d => d.name,
      width: 300,
      EditWith: Input,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Slug',
      id: 'slug',
      accessor: d => d.slug,
      width: 280,
      EditWith: Input,
      style: {
        textAlign: 'center',
      },
    },

    {
      editable: true,
      Header: 'Partner',
      id: 'partner',
      width: 180,
      accessor: ({ partner }) => (partner ? 'Is Partner' : 'Not Partner'),
      editAccessor: d => partnerOptions.find(({ value }) => value === d.parner),
      EditWith: Select,
      editProps: { options: partnerOptions },
      style: {
        textAlign: 'center',
      },
    },
  ];
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
  save = async changes => {
    try {
      await apollo.mutate({
        mutation:
          changes.id === 0 ? CreateCompanyMutation : UpdateCompanyMutation,
        variables:
          changes.id === 0
            ? { input: { company: omit(changes, ['id']) } }
            : {
                input: { id: changes.id, companyPatch: omit(changes, ['id']) },
              },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  render() {
    return (
      <>
        <h1>
          Companies{' '}
          <Button
            onClick={() =>
              this.table.current.newRow({
                name: '',
                slug: '',
              })
            }
          >
            Add Company
          </Button>
        </h1>
        <EditableTable
          ref={this.table}
          queryName="allCompanies"
          save={this.save}
          query={AllCompaniesQuery}
          columns={this.columns}
        />
      </>
    );
  }
}
