import React from 'react';
import '../react-table.css';
import styled from 'styled-components';
import Markdown from 'react-remarkable';
import { Link } from 'react-router-dom';
import apollo from '../lib/apollo';
import { omit } from 'lodash';
import Button from '../components/Button';
import AllTextblocksQuery from '../graph/queries/AllTextblocksQuery';
import Table from '../components/Table';
import DeleteTextblockMutation from '../graph/mutations/DeleteTextblockMutation';

const LongTextCell = styled.div`
  width: 100%;
  overflow: auto;
  white-space: normal;
  text-align: left;
  background: rgba(255, 255, 255, 0.3);
  padding: 14px 14px 0px 14px;
  border: 1px solid #eee;
  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;
const MedTextCell = styled.div`
  width: 100%;
  overflow: auto;
  white-space: normal;
  background: rgba(255, 255, 255, 0.3);
  border: 1px solid #eee;
`;

export default class TextblocksPage extends React.Component {
  state = {
    editing: false,
    view: 'all',
    filter: { filter: { status: { isNull: false } } },
    confirmDelete: false,
  };
  columns = [
    {
      Header: 'ID',
      id: 'id',
      accessor: d => d.id,
      width: 80,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Group',
      id: 'group',
      accessor: d => d.group,
      Cell: ({ column, original }) => (
        <MedTextCell>
          <Markdown>{column.accessor(original)}</Markdown>
        </MedTextCell>
      ),
      width: 340,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Section',
      id: 'section',
      accessor: d => d.section,
      width: 150,
      style: {
        textAlign: 'center',
      },
      Cell: ({ column, original }) => (
        <MedTextCell>
          <Markdown>{column.accessor(original)}</Markdown>
        </MedTextCell>
      ),
    },
    {
      Header: 'Content',
      id: 'content',
      width: 280,
      accessor: ({ content }) => content,
      Cell: ({ column, original }) => (
        <LongTextCell>
          <Markdown>{`${column.accessor(original).substr(0, 60)}...`}</Markdown>
        </LongTextCell>
      ),
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ original }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Button as={Link} to={`/textblock/${original.id}`}>
            Edit
          </Button>
          <Button
            mode="mild-danger"
            onClick={() =>
              this.state.confirmDelete &&
              this.state.confirmDelete.id === original.id
                ? this.delete()
                : this.confirmDelete(original)
            }
          >
            {this.state.confirmDelete &&
            this.state.confirmDelete.id === original.id
              ? 'Click Again to Delete'
              : 'Delete'}
          </Button>
        </div>
      ),
      width: 214,
      style: {
        textAlign: 'center',
      },
    },
  ];
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
  confirmDelete = original => {
    this.setState({ confirmDelete: original });
    this.delTimo = setTimeout(() => {
      this.setState({ confirmDelete: false });
    }, 800);
  };
  // save = async changes => {
  //   try {
  //     await apollo.mutate({
  //       mutation: UpdateTextblockMutation,
  //       variables: {
  //         input: { id: changes.id, questionPatch: omit(changes, ['id']) },
  //       },
  //     });
  //   } catch (e) {
  //     alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
  //   }
  // };
  changeFilter = ({ value, filter }) => {
    this.setState({
      filter: filter ? { filter: { ...filter } } : null,
      view: value,
    });
  };
  delete = async () => {
    try {
      this.setState({ confirmDelete: false });
      await apollo.mutate({
        mutation: DeleteTextblockMutation,
        variables: { input: { id: this.state.confirmDelete.id } },
      });
      this.table.current.refetch();
    } catch (e) {
      alert('Hm, there was an error: ', e.message);
    }
  };
  render() {
    return (
      <>
        <h1>
          Textblocks
          <Button as={Link} to="/create-textblock">
            Add Textblock
          </Button>
        </h1>
        <Table
          ref={this.table}
          queryName="allTextblocks"
          save={this.save}
          query={AllTextblocksQuery}
          variables={this.state.filter}
          columns={this.columns}
          className="center-cells"
        />
      </>
    );
  }
}
