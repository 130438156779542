import gql from 'graphql-tag';
export default gql`
  query {
    allTextblocks {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          group
          section
          headline
          content
          context
          createdAt
          updatedAt
        }
      }
    }
  }
`;
