import gql from 'graphql-tag';
export default gql`
  query AllQuestions($filter: QuestionFilter) {
    allQuestions(filter: $filter) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          question
          uri
          ipaddress
          status
        }
      }
    }
  }
`;
