import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import usePortal from 'react-useportal';
import Select from 'react-select';
import Textarea from './Textarea';
import Spinner from 'node-spintax';
import { monaco, ControlledEditor } from '@monaco-editor/react';
import { IoMdOpen, IoMdExpand } from 'react-icons/io';
import { mean } from 'lodash';
import Modal from './Modal';
import monacoAutocompleteObject from '../lib/monacoAutocompleteObject';
import TextSampleGenerator from './TextSampleGenerator';
import Variations from './Variations';
import { Button } from './Form';

const ContextOptions = [
  { label: 'Basic Page', value: 'basic' },
  { label: 'City Page', value: 'city' },
];
const StateBase = {
  name: '',
  population: 0,
};
const CityBase = {
  city_name: '',
  population: 0,
  total_crimes_perhr: 0,
  percentile_total_crimes: 0,
  total_crimes_perhr_percentile_group: 0,
  person_crimes_perhr: 0,
  percentile_person_crimes: 0,
  person_crimes_perhr_percentile_group: 0,
  property_crimes_perhr: 0,
  percentile_property_crimes: 0,
  property_crimes_perhr_percentile_group: 0,
  total_crimes_per_1000_capita: 0,
  percentile_total_crime_rate: 0,
  total_crimes_rate_percentile_group: 0,
  person_crimes_per_1000_capita: 0,
  percentile_person_crime_rate: 0,
  person_crimes_rate_percentile_group: 0,
  property_crimes_per_1000_capita: 0,
  percentile_property_crime_rate: 0,
  property_crimes_rate_percentile_group: 0,
  lawenf_per_1000_capita: 0,
  percentile_lawenf_rate: 0,
  lawenf_rate_percentile_group: 0,
  total_crimes_to_state: 0,
  total_crimes_to_state_rel: 0,
  total_crimes_to_us: 0,
  total_crimes_to_us_rel: 0,
  property_crimes_to_state: 0,
  property_crimes_to_state_rel: 0,
  property_crimes_to_us: 0,
  property_crimes_to_us_rel: 0,
  person_crimes_to_state: 0,
  person_crimes_to_state_rel: 0,
  person_crimes_to_us: 0,
  person_crimes_to_us_rel: 0,
  total_crimes: 0,
  property_crimes_total: 0,
  person_crimes_total: 0,
  total_lawenf: 0,
  mcc: '',
  mcpropc: '',
  mcpersc: '',
  mccc: '',
};

const Bal = styled.div`
  margin-top: 4px;
  margin-bottom: 4px;
  background: #fef1d3;
`;
const Status = styled.div`
  padding: 7px 6px 5px;
  background: #eaeae8;
  font-family: courier;
  font-weight: bold;
  font-size: 10.4pt;
  color: #514f4b;
  border-right: 13px solid #f4f4f4;
`;
const BalRow = styled.div`
  padding: 6px;
  font-size: 14px;
  color: #987604;
  margin-bottom: -4px;
  font-family: courier;
  font-size: 10pt;
`;
const Label = styled.label`
  display: block;
  font-weight: bold;
  margin-top: 0.7rem;
  margin-bottom: 0.3rem;
`;
const Controls = styled.div`
  display: flex;
  position: absolute;
  right: -6px;
  top: -23px;
`;
const Open = styled.button`
  border: 0;
  background: transparent;
  padding: 4px;
`;
const Spins = styled.div`
  width: 100%;
  max-height: 500px;
  overflow: auto;
`;
const FSShell = styled.div`
  display: flex;
  > div {
    flex: 1;
    width: 50%;

    &:first-child {
      margin-right: 3rem;
    }
  }
`;

monaco
  .init()
  .then(monaco => {
    window.m = monaco;
    monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
      noLib: true,
      allowNonTsExtensions: true,
    });
    monacoAutocompleteObject(monaco, {
      City: CityBase,
      State: StateBase,
      Section: fnc => {},
      Paragraph: fnc => {},
      Text: text => {},
      percentileJudgement: val => {},
      frequencyJudgement: val => {},
      round: (val, num) => {},
      percentile: val => {},
      perSmallTimeUnit: val => {},
    });
  })
  .catch(error =>
    console.error('An error occurred during initialization of Monaco: ', error),
  );

export default ({
  Component,
  onChange,
  onBlur,
  value = '',
  name,
  style,
  context = 'basic',
  editorContext = {},
  onContextChange = () => {},
}) => {
  // React init
  const [variations, setVariations] = useState(0);
  const [count, setCount] = useState(0);
  const [unbalanced, setUnbalanced] = useState([]);
  const [pCount, setPCount] = useState(0);
  let componentProps = { onChange, value };
  const { isSubmitting, status, submitForm } = editorContext;
  let calcTimo = 0;

  // const handleEditorDidMount = (_, editor) => {
  //   // editorRef = editor;
  //   // editorRef.languages;
  // };
  if (Component === Textarea) {
    Component = ControlledEditor;
    componentProps = {
      theme: 'vs-light',
      height: '400px',
      language: context === 'basic' ? 'text' : 'javascript',
      value,
      onChange: (ev, value) => {
        onChange({ target: { value, name } });
      },
      options: {
        wordWrap: 'on',
        lineNumbers: context === 'basic' ? 'off' : 'on',
        wrappingIndent: 'same',
        minimap: { enabled: false },
      },
    };
  }

  useEffect(() => {
    clearTimeout(calcTimo);
    calcTimo = setTimeout(() => {
      const spin = new Spinner(value);
      const variations = spin.countVariations();
      const count = Math.floor(
        mean(spin.unspin(true, 1000).map(v => v.split(' ').length)),
      );
      setCount(count);
      setVariations(variations);
    }, 500);
    const balance = [];
    const pgraphs = value.split('\n\n');
    pgraphs.forEach((content, index) => {
      const opens = content.split('{').length - 1;
      const closes = content.split('}').length - 1;
      if (opens !== closes) {
        balance.push({ paragraph: index + 1, opens, closes });
      }
    });
    setUnbalanced(balance);
    setPCount(pgraphs.length);
  }, [value]);

  // End React Init

  const hasStatus =
    (value.length && parseInt(variations) > 0) ||
    parseInt(count) > 0 ||
    unbalanced.length > 0;

  // Balance Status
  const renderStatus = () => {
    if (!hasStatus) return;
    let out = `${variations.toLocaleString()} ${
      variations === 1 ? 'variation' : 'variations'
    }`;
    out += `, ${variations > 1 ? '~' : ''}${count.toLocaleString()} ${
      count === 1 ? 'word' : 'words'
    }`;
    return <Status>{out}</Status>;
  };

  const renderBalance = () => {
    if (!hasStatus || !unbalanced.length) return;
    return (
      <Bal>
        {unbalanced.map(v => {
          const moreOpen = v.opens > v.closes;
          let extra = moreOpen ? v.opens - v.closes : v.closes - v.opens;
          let character = moreOpen ? '{' : '}';
          return (
            <BalRow>
              {`Unbalanced in paragraph ${v.paragraph}:`}
              <b> {`${extra} extra "${character}"`}</b>
            </BalRow>
          );
        })}
      </Bal>
    );
  };

  var { openPortal, closePortal: cPort, isOpen, Portal } = usePortal({
    closeOnOutsideClick: false,
  });
  var {
    openPortal: openFS,
    closePortal: closeFS,
    isOpen: isFSOpen,
    Portal: FullScreen,
  } = usePortal({
    closeOnOutsideClick: false,
  });

  return (
    <div style={{ position: 'relative' }}>
      {Component === ControlledEditor && (
        <>
          <Label>Page Type</Label>
          <Select
            value={ContextOptions.find(row => row.value === context)}
            options={ContextOptions}
            onChange={onContextChange}
            styles={{
              control: provided => ({
                ...provided,
                width: 200,
                marginBottom: 10,
              }),
            }}
          />
        </>
      )}
      <Controls>
        <Open onClick={() => openPortal()}>
          <IoMdOpen size={20} color="#666" />
        </Open>
        {Component === ControlledEditor && (
          <Open onClick={() => openFS()}>
            <IoMdExpand size={20} color="#666" />
          </Open>
        )}
      </Controls>
      {!isFSOpen && (
        <Component
          style={style}
          name={name}
          onBlur={onBlur}
          {...componentProps}
        />
      )}
      {renderBalance()}
      {renderStatus()}
      <FullScreen>
        <Modal isOpen={isFSOpen} handleClose={closeFS} fullScreen={true}>
          <FSShell>
            <div>
              {isFSOpen && (
                <>
                  <Component
                    style={style}
                    name={name}
                    onBlur={onBlur}
                    {...{
                      ...componentProps,
                      height: `${window.innerHeight - 200}px`,
                    }}
                  />
                  {renderBalance()}
                  {renderStatus()}
                  <Button
                    type="submit"
                    disabled={isSubmitting}
                    onClick={() => submitForm()}
                    style={{ marginTop: '10px' }}
                  >
                    {isSubmitting
                      ? 'Saving...'
                      : status === 'success'
                      ? 'Saved!'
                      : 'Save'}
                  </Button>
                </>
              )}
            </div>
            <div>
              {context === 'city' && (
                <>
                  <h3>City Text</h3>
                  {isFSOpen && (
                    <>
                      <TextSampleGenerator
                        text={value}
                        showStatus={isFSOpen}
                        style={{
                          overflow: 'auto',
                          height: 'calc(100% + -45px)',
                        }}
                      />
                    </>
                  )}
                </>
              )}
            </div>
          </FSShell>
        </Modal>
      </FullScreen>
      <Portal>
        <Modal isOpen={isOpen} handleClose={cPort}>
          {context === 'basic' && (
            <>
              <h3>Variations</h3>
              {isOpen && (
                <Spins>
                  <Variations value={value} />
                </Spins>
              )}
            </>
          )}
          {context === 'city' && (
            <>
              <h3>City Text</h3>
              {isOpen && (
                <TextSampleGenerator text={value} showStatus={isOpen} />
              )}
            </>
          )}
        </Modal>
      </Portal>
    </div>
  );
};
