import React from 'react';
import { Link } from 'react-router-dom';
import '../react-table.css';
import { omit, kebabCase } from 'lodash';
import styled from 'styled-components';
import dayjs from 'dayjs';
import Input from '../components/Input';
import AllTablesQuery from '../graph/queries/AllTablesQuery';
import apollo from '../lib/apollo';
import UpdatePhoneMutation from '../graph/mutations/UpdatePhoneMutation';
import Button from '../components/Button';
import CreatePhoneMutation from '../graph/mutations/CreatePhoneMutation';
import Table from '../components/Table';
import DeleteTableMutation from '../graph/mutations/DeleteTableMutation';
import CreateTableMutation from '../graph/mutations/CreateTableMutation';
import AllBuildLogsQuery from '../graph/queries/AllBuildLogsQuery';
import BuildStatus from '../components/BuildStatus';

const MedTextCell = styled.div`
  width: 100%;
  white-space: normal;
`;
export default class TablesPage extends React.Component {
  state = {
    confirmDelete: false,
  };
  columns = [
    {
      Header: 'Machine',
      id: 'machine',
      accessor: d => d.machine,
      width: 140,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Env',
      id: 'env',
      accessor: d => d.env,
      width: 140,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Time',
      id: 'time',
      accessor: d =>
        dayjs(d.timestamp)
          .subtract(new Date().getTimezoneOffset(), 'm')
          .format('MM-DD-YY [at] H:mm'),
      width: 160,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Issues',
      id: 'issues',
      accessor: ({ stats }) => {
        if (!stats) return '';
        return JSON.parse(stats).numberOfIssues;
      },
      width: 80,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Critical',
      id: 'critical',
      accessor: ({ stats }) => {
        if (!stats) return '';
        return JSON.parse(stats).highPriorityIssues;
      },
      width: 80,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Pages with Iss.',
      id: 'pagepcnt',
      accessor: ({ stats }) => {
        if (!stats) return '';
        const { numberOfPages, pagesWithIssues } = JSON.parse(stats);
        return `${(pagesWithIssues / numberOfPages) * 100}%`;
      },
      width: 150,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ original: { id } }) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Button as={Link} to={`/build/${id}`}>
            View Details
          </Button>
        </div>
      ),
      width: 152,
      style: {
        textAlign: 'center',
      },
    },
  ];
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
  render() {
    return (
      <>
        <BuildStatus />
        <h1>Latest Builds</h1>
        <Table
          ref={this.table}
          queryName="allBuildLogs"
          className="center-cells"
          save={this.save}
          query={AllBuildLogsQuery}
          columns={this.columns}
        />
      </>
    );
  }
}
