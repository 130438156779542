import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import styled from 'styled-components';
import { isArray, debounce } from 'lodash';
import cities from '../constants/cities';
import { API_URL } from '../constants/Net';
import Variations from './Variations';

const cityOptions = cities.map(({ id, name }) => ({
  label: name,
  value: id.toString(),
}));

const Shell = styled.div`
  label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.2rem;
    margin-top: 2rem;
  }
  .variation-row {
    margin-top: 0;
    padding: 1rem;
    p {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`;

const sync = debounce(
  async (content, objId, cb) => {
    const res = await axios.post(`${API_URL}/dynamictext`, {
      context: 'city',
      content,
      obj_id: objId,
      sample_key: window.location.href,
    });
    cb(res);
    return res;
  },
  400,
  { maxWait: 900 },
);
export default ({ text, showStatus, showLoading, style }) => {
  const [objId, setObjId] = useState('820');
  const [compiledValue, setCompiledValue] = useState('');
  const [loading, setLoading] = useState(false);
  const fetchText = async (content, objId) => {
    if (showLoading) {
      setLoading(true);
      setCompiledValue(false);
    }
    sync(content, objId, res => {
      setLoading(false);
      setCompiledValue(res.data.text);
    });
  };
  useEffect(() => {
    fetchText(text, objId);
  }, [text, showStatus, objId]);
  useEffect(() => {
    fetchText(text, objId);
  }, []);
  return (
    <Shell style={style}>
      <label>Generate City</label>
      <Select
        value={cityOptions.find(row => row.value === objId)}
        options={cityOptions}
        onChange={({ value }) => setObjId(value)}
        styles={{
          control: provided => ({
            ...provided,
            width: 200,
            marginBottom: 10,
          }),
        }}
      />
      <div>
        {loading && <div>Loading...</div>}
        {isArray(compiledValue) && (
          <>
            <label>Generated Text</label>
            <div>
              {compiledValue.map(p => (
                <p>{p}</p>
              ))}
            </div>
          </>
        )}
      </div>
    </Shell>
  );
};
