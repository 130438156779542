import gql from 'graphql-tag';
export default gql`
  query AllContacts($filter: ContactFilter) {
    allContacts(filter: $filter) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          message
          name
          reason
          readAt
          email
        }
      }
    }
  }
`;
