import React, { Component } from 'react';
import EditTextblockPage from './pages/EditTextblockPage';
import {
  BrowserRouter as Router,
  Route,
  Link,
  Redirect,
} from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
// import CitiesPage from './pages/CitiesPage';
import TextblocksPage from './pages/TextblocksPage';
import CreateTextblockPage from './pages/CreateTextblockPage';
import { Normalize } from 'styled-normalize';
import { GlobalStyles } from './components/GlobalStyles';
import Layout from './components/Layout';
import PhonesPage from './pages/PhonesPage';
import AffiliateLinksPage from './pages/AffiliateLinksPage';
import DashboardPage from './pages/DashboardPage';
import apollo from './lib/apollo';
import TablesPage from './pages/TablesPage';
import CompaniesPage from './pages/CompaniesPage';
import CreateTablePage from './pages/CreateTablePage';
import EditTablePage from './pages/EditTablePage';
import ReviewsPage from './pages/ReviewsPage';
import QuestionsPage from './pages/QuestionsPage';
import BuildPage from './pages/BuildPage';
import DataPage from './pages/DataPage';
import NotFoundsPage from './pages/NotFoundsPage';
import LoginPage from './pages/LoginPage';
import ContactsPage from './pages/ContactsPage';
import { AuthContextProvider, AuthContext } from './lib/AuthContextProvider';

function PrivateRoute({ component: Component, ...rest }) {
  return (
    <AuthContext.Consumer>
      {({ isAuthd }) => (
        <Route
          {...rest}
          render={props =>
            isAuthd ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: props.location },
                }}
              />
            )
          }
        />
      )}
    </AuthContext.Consumer>
  );
}

class App extends Component {
  componentDidMount() {}
  render() {
    return (
      <AuthContextProvider>
        <AuthContext.Consumer>
          {({ checked }) =>
            checked ? (
              <ApolloProvider client={apollo}>
                <Normalize />
                <GlobalStyles />
                <Router>
                  <Layout>
                    <PrivateRoute path="/" exact component={DashboardPage} />
                    <PrivateRoute
                      path="/links/"
                      component={AffiliateLinksPage}
                    />
                    <PrivateRoute path="/phones" component={PhonesPage} />
                    <PrivateRoute path="/questions" component={QuestionsPage} />
                    <PrivateRoute
                      path="/textblocks"
                      component={TextblocksPage}
                    />
                    <PrivateRoute
                      path="/create-textblock"
                      component={CreateTextblockPage}
                    />
                    <PrivateRoute
                      path="/textblock/:id"
                      component={EditTextblockPage}
                    />
                    <PrivateRoute path="/tables" component={TablesPage} />
                    <PrivateRoute path="/reviews" component={ReviewsPage} />
                    {/* <PrivateRoute path="/cities" component={CitiesPage} /> */}
                    {/* <PrivateRoute path="/add-city" component={AddCityPage} />
                    <PrivateRoute path="/city/:id" component={CityPage} /> */}
                    <PrivateRoute path="/table/:id" component={EditTablePage} />
                    <PrivateRoute path="/build/:id" component={BuildPage} />
                    <PrivateRoute path="/companies" component={CompaniesPage} />
                    <PrivateRoute
                      path="/create-table"
                      component={CreateTablePage}
                    />
                    <PrivateRoute path="/404s" component={NotFoundsPage} />
                    <PrivateRoute path="/contacts" component={ContactsPage} />
                    <PrivateRoute path="/data" component={DataPage} />
                    <Route exact path="/login" component={LoginPage} />
                    <Route path="/login/:hash" component={LoginPage} />
                  </Layout>
                </Router>
              </ApolloProvider>
            ) : null
          }
        </AuthContext.Consumer>
      </AuthContextProvider>
    );
  }
}

export default App;
