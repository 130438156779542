import React from 'react';
import '../react-table.css';
import apollo from '../lib/apollo';
import { Formik } from 'formik';
import UpdateTableMutation from '../graph/mutations/UpdateTableMutation';
import TableByIdQuery from '../graph/queries/TableByIdQuery';
import {
  FormRow,
  Form,
  Input,
  Button,
  Label,
  FormBox,
} from '../components/Form';
import { TableBuilder, genRows } from '../components/TableBuilder';
import { Query } from 'react-apollo';
import Loading from '../components/Loading';

export default class EditTablePage extends React.Component {
  submit = async ({ name, json }, { setSubmitting, setStatus }) => {
    try {
      await apollo.mutate({
        mutation: UpdateTableMutation,
        variables: {
          input: {
            id: +this.props.match.params.id,
            tablePatch: {
              name,
              json: JSON.stringify(json),
            },
          },
        },
      });
      setSubmitting(false);
      setStatus('success');
      setTimeout(() => {
        setStatus('ready');
      }, 5);
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  renderError = error => {
    console.log(error);
    return <div>Error</div>;
  };
  render() {
    return (
      <Query
        query={TableByIdQuery}
        variables={{ id: +this.props.match.params.id }}
      >
        {({ loading, data, error }) => {
          if (error) return this.renderError(error);
          else if (loading) return <Loading />;
          else {
            const { name, json } = data.tableById;
            return (
              <>
                <Formik
                  initialValues={{
                    name,
                    cols: 3,
                    rows: 5,
                    json: JSON.parse(json),
                  }}
                  onSubmit={this.submit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    status,
                    setFieldValue,
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      {errors.name && touched.name}
                      <Input
                        type="name"
                        name="name"
                        className="heading"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.name}
                      />
                      <FormBox />
                      <TableBuilder
                        name="json"
                        onChange={setFieldValue}
                        value={values.json}
                      />
                      <FormRow>
                        <FormBox>
                          <Button type="submit" disabled={isSubmitting}>
                            {isSubmitting
                              ? 'Saving...'
                              : status === 'success'
                              ? 'Saved!'
                              : 'Save'}
                          </Button>
                        </FormBox>
                      </FormRow>
                    </Form>
                  )}
                </Formik>
              </>
            );
          }
        }}
      </Query>
    );
  }
}
