import React from 'react';
import Select from 'react-select';
import { Query } from 'react-apollo';
import AllCompaniesQuery from '../graph/queries/AllCompaniesQuery';
import unpackEdge from '../lib/unpackEdge';

/*
 *
 */
export default props => (
  <Query query={AllCompaniesQuery} notifyOnNetworkStatusChange={true}>
    {({ loading, error, data, refetch }) => {
      if (loading) return <div />;
      const rows = unpackEdge(data, 'allCompanies').map(({ id, name }) => ({
        value: id,
        label: name,
      }));
      const { value, defaultValue } = props;
      const val = value || defaultValue;
      return (
        <Select
          {...props}
          value={rows.find(row => row.value === val)}
          options={rows}
          styles={{
            control: provided => ({
              ...provided,
              width: 200,
            }),
          }}
        />
      );
    }}
  </Query>
);
