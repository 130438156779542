import styled from 'styled-components';
import { Colors } from '../constants/Style';
import { lighten, darken } from 'polished';

/*
 * Available standard form components
 */
export const Form = styled.form`
  width: 64rem;
`;
export const FormRow = styled.div`
  display: flex;
  padding: 2.4rem;
  background: ${lighten(0.07, Colors.lightBlueGray)};
  min-width: 20%;
  margin-bottom: 1rem;
`;

export const FormBox = styled.div`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth || '40rem'};
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;
export const Label = styled.label`
  font-size: 1.3rem;
  font-weight: 700;
  color: ${Colors.darkBlueGray};
  margin-bottom: 0.2rem;
  display: block;
`;

export const Button = styled.button`
  display: inline;
  border: 1px solid #ced7da;
  border-radius: 3px;
  background: #fff;
  text-decoration: none;
  padding: 15px 20px 10px;
  margin-bottom: -2px;
  position: relative;
  color: ${({ mode }) =>
    mode === 'mild-danger' ? Colors.mildRed : Colors.darkBlueGray};
  font-size: 1.14rem;
  cursor: pointer;
  transition: 0.24s all;
  font-weight: 600;
  font-family: sans-serif;
  width: ${({ width }) => width || '160px'};
  box-shadow: 1px 1px 1px rgba(206, 215, 218, 0.55);
  &:hover {
    background: ${({ mode }) =>
      mode === 'mild-danger'
        ? lighten(0.44, Colors.mildRed)
        : Colors.whiteBlue};
  }
`;

export const Input = styled.input`
  padding: 10px;
  border: 1px solid #ddd;
  font-family: 'Assistant';
  width: 100%;
  font-size: 1.1rem;
  font-weight: 600;
  color: ${darken(0.3, Colors.darkBlueGray)};
  &.heading {
    font-size: 2.2rem;
    margin-top: 2rem;
  }
`;
