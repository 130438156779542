import React from 'react';
import styled from 'styled-components';
import { IoMdClose } from 'react-icons/io';

const Content = styled.div.attrs({ className: 'modal-content' })`
  position: relative;
  width: 720px;
  box-shadow: 6px 6px 12px rgba(0, 0, 0, 0.05);
  margin: 80px auto 0;
  padding: 40px;
  background: #fff;
`;

const Shell = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(236, 236, 236, 0.9);
  opacity: 0;
  transition: opacity 2s 2s;
  left: -10000px;

  &.open {
    opacity: 1;
    left: 0;
    transition: opacity 2s 2s;
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0px;
  }
  &.fullscreen .modal-content {
    width: calc(100% - 20px);
    height: calc(100% - 40px);
    margin-top: 32px;
  }
`;
const Close = styled.button`
  background: transparent;
  border: 0;
  position: absolute;
  right: -12px;
  top: -25px;
  cursor: pointer;
`;

export default ({ children, isOpen, handleClose, fullScreen }) => {
  return (
    <Shell
      className={`${isOpen ? 'open' : 'closed'} ${
        fullScreen ? 'fullscreen' : ''
      }`}
    >
      <Content>
        <Close onClick={() => handleClose()}>
          <IoMdClose size={20} color="#666" />
        </Close>
        {children}
      </Content>
    </Shell>
  );
};
