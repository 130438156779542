import React from 'react';
import '../react-table.css';
import { kebabCase } from 'lodash';
import apollo from '../lib/apollo';
import { Formik } from 'formik';
import CreateTableMutation from '../graph/mutations/CreateTableMutation';
import {
  FormRow,
  Form,
  Input,
  Button,
  Label,
  FormBox,
} from '../components/Form';
import { genRows } from '../components/TableBuilder';

export default class CreateTablePage extends React.Component {
  submit = async ({ name, cols, rows }, { setSubmitting, setStatus }) => {
    try {
      const rsp = await apollo.mutate({
        mutation: CreateTableMutation,
        variables: {
          input: {
            table: {
              name,
              slug: kebabCase(name),
              json: JSON.stringify({ meta: '', data: genRows(rows, cols) }),
            },
          },
        },
      });
      setSubmitting(false);
      setStatus('success');
      setTimeout(() => {
        this.props.history.push(`/table/${rsp.data.createTable.table.id}`);
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  render() {
    return (
      <>
        <h1>Create a Table</h1>
        <Formik
          initialValues={{
            name: '',
            cols: 3,
            rows: 5,
            json: JSON.stringify({}),
          }}
          onSubmit={this.submit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            status,
            setFieldValue,
          }) => (
            <Form onSubmit={handleSubmit}>
              <FormRow>
                <FormBox>
                  <Label>Table Name</Label>
                  {errors.name && touched.name}
                  <Input
                    type="name"
                    name="name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.email}
                  />
                </FormBox>
                <FormBox />
              </FormRow>
              <FormRow>
                <FormBox>
                  <Label>Table Starting Rows</Label>
                  <Input
                    type="number"
                    name="rows"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.rows}
                  />
                </FormBox>
                <FormBox>
                  <Label>Table Starting Columns</Label>
                  <Input
                    type="number"
                    name="cols"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.cols}
                  />
                </FormBox>
              </FormRow>
              <FormRow>
                <FormBox>
                  <Button type="submit" disabled={isSubmitting}>
                    {isSubmitting
                      ? 'Creating...'
                      : status === 'success'
                      ? 'Created!'
                      : 'Create'}
                  </Button>
                </FormBox>
              </FormRow>
            </Form>
          )}
        </Formik>
      </>
    );
  }
}
