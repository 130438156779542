import React from 'react';
import '../react-table.css';
import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import styled from 'styled-components';
import axios from 'axios';
import ReactTable from 'react-table';
import Loading from '../components/Loading';
import AllDatasetsQuery from '../graph/queries/AllDatasetsQuery';
import Button from '../components/Button';
import unpackEdge from '../lib/unpackEdge';

const LongTextCell = styled.div`
  width: 100%;
  max-height: 120px;
  overflow: auto;
  white-space: normal;
  text-align: left;
  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;
const MedCell = styled.div`
  width: 100%;
  max-height: 120px;
  white-space: normal;
  text-align: left;
  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;
const Tag = styled.div`
  padding: 3px 8px;
  font-size: 14px;
  background: #d8f0f9;
  margin-right: 3px;
  margin-bottom: 3px;
  color: #586366;
  font-weight: 600;
`;

const download = async tableName => {
  window.location = `http://api.homealarmreport.com/export?table=${tableName}&harkey_1819471291736127=aafebf2cce6f16df695c10b20eef9501cd99753b`;
};

export default class DataPage extends React.Component {
  state = {
    sorted: [],
    page: 0,
    pageSize: 75,
    resized: [],
    filtered: [],
    editing: false,
    show: 'all',
  };
  renderError = error => {
    return <div>Error</div>;
  };
  render() {
    return (
      <Query query={AllDatasetsQuery}>
        {({ loading, data, error }) => {
          if (error) return this.renderError(error);
          else if (loading) return <Loading />;
          else {
            const rows = unpackEdge(data, 'getDataTables');
            const columns = [
              {
                Header: 'Title',
                id: 'title',
                accessor: d => d.description.split(':')[0],
                width: 340,
                style: {
                  textAlign: 'center',
                },
                Cell: ({ column, original }) => (
                  <MedCell>
                    <b>{column.accessor(original)}</b>
                    <div style={{ fontSize: '13px', marginTop: '2px' }}>
                      {original.description.split(':')[1].split('[')[0]}
                    </div>
                  </MedCell>
                ),
              },
              {
                Header: 'Records',
                id: 'rows',
                accessor: d => d.numRows,
                width: 75,
                style: {
                  textAlign: 'center',
                },
                filterable: false,
              },
              {
                Header: 'Tags',
                id: 'tags',
                accessor: d => {
                  const byBrace = d.description.split(':')[1].split('[');
                  if (byBrace[1]) {
                    return byBrace[1]
                      .replace(']', '')
                      .split(',')
                      .map(val => val.trim())
                      .join(',');
                  }
                },
                filterMethod: ({ value }, obj) => {
                  if (obj.hasOwnProperty('tags') && obj.tags) {
                    return (
                      obj.tags.split(',').filter(val => val.includes(value))
                        .length > 0
                    );
                  } else {
                    return false;
                  }
                },
                width: 240,
                style: {
                  textAlign: 'center',
                },
                Cell: ({ column, original }) => {
                  const byBrace = original.description.split(':')[1].split('[');
                  if (byBrace[1]) {
                    return (
                      <>
                        {byBrace[1]
                          .replace(']', '')
                          .split(',')
                          .map(tag => (
                            <Tag>{tag}</Tag>
                          ))}
                      </>
                    );
                  }
                  return <div />;
                  // <MedCell>{column.accessor(original)}</MedCell>
                },
              },
              {
                Header: 'Fields',
                id: 'fields',
                accessor: d => d.cols.replace(/,/g, ', '),
                width: 240,
                style: {
                  textAlign: 'center',
                },
                Cell: ({ column, original }) => (
                  <LongTextCell
                    style={{ fontSize: '11.8px', fontFamily: 'courier new' }}
                  >
                    {column.accessor(original)}
                  </LongTextCell>
                ),
              },
              {
                Header: 'Table',
                id: 'table',
                accessor: d => d.tableName,
                width: 280,
                filterable: false,
                style: {
                  textAlign: 'center',
                },
                Cell: ({ column, original }) => (
                  <LongTextCell
                    style={{ fontSize: '12px', fontFamily: 'courier new' }}
                  >
                    {column.accessor(original)}
                  </LongTextCell>
                ),
              },
              {
                Header: '',
                id: 'actions',
                Cell: ({ original: { id, tableName } }) => (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Button onClick={() => download(tableName).then()}>
                      Export CSV
                    </Button>
                  </div>
                ),
                width: 152,
                style: {
                  textAlign: 'center',
                },
              },
            ];
            const { state } = this;
            return (
              <>
                <h2>Available Data Sets</h2>
                <div style={{ width: '100%' }}>
                  <ReactTable
                    data={rows}
                    columns={columns}
                    defaultPageSize={48}
                    pageSizeOptions={[5, 10, 20, 25, 75, 100]}
                    className={`-striped -highlight center-cells`}
                    // Controlled props
                    sorted={state.sorted}
                    filterable={true}
                    page={state.page}
                    pageSize={state.pageSize}
                    resized={state.resized}
                    filtered={state.filtered}
                    // Callbacks
                    onSortedChange={sorted => this.setState({ sorted })}
                    onPageChange={page => this.setState({ page })}
                    onPageSizeChange={(pageSize, page) =>
                      this.setState({ page, pageSize })
                    }
                    onExpandedChange={expanded => this.setState({ expanded })}
                    onResizedChange={resized => this.setState({ resized })}
                    onFilteredChange={filtered => this.setState({ filtered })}
                  />
                </div>
              </>
            );
          }
        }}
      </Query>
    );
  }
}
