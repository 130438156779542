import gql from 'graphql-tag';
export default gql`
  query {
    allCompanies {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          slug
          partner
          name
          createdAt
        }
      }
    }
  }
`;
