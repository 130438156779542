import gql from 'graphql-tag';
export default gql`
  query {
    allBuildLogs(
      first: 100
      orderBy: ID_DESC
      condition: { machine: "netlify" }
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          status
          env
          machine
          stats
          timestamp
        }
      }
    }
  }
`;
