import React from 'react';
import '../react-table.css';
import Select from 'react-select';
import styled from 'styled-components';
import { omit } from 'lodash';
import dayjs from 'dayjs';
import axios from 'axios';
import AllNotFoundsQuery from '../graph/queries/AllNotFoundsQuery';
import UpdateNotFoundMutation from '../graph/mutations/UpdateNotFoundMutation';
import apollo from '../lib/apollo';
import EditableTable from './EditableTable';
import Button from '../components/Button';
import { API_URL } from '../constants/Net';

const LongTextCell = styled.div`
  width: 100%;
  max-height: 120px;
  overflow: auto;
  white-space: normal;
  text-align: left;
  background: rgba(255, 255, 255, 0.3);
  padding: 14px;
  border: 1px solid #eee;
  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;
const MedTextCell = styled.div`
  width: 100%;
  overflow: auto;
  white-space: normal;
  background: rgba(255, 255, 255, 0.3);
`;
const viewOptions = [
  { value: 'all', label: 'Show All', filter: { status: { isNull: false } } },
  {
    value: 'unreview',
    label: 'Unreviewed',
    filter: { status: { equalTo: 'not-reviewed' } },
  },
  {
    value: 'reviewed',
    label: 'Reviewed',
    filter: { status: { equalTo: 'reviewed' } },
  },
];

const statusOptions = [
  { value: 'reviewed', label: 'Reviewed' },
  { value: 'not-reviewed', label: 'Not Reviewed' },
];

export default class NotFoundsPage extends React.Component {
  state = {
    editing: false,
    view: 'all',
    markReviewedBtn: 'Mark All Reviewed',
    filter: { filter: { status: { isNull: false } } },
  };
  columns = [
    {
      Header: 'Path',
      id: 'path',
      accessor: d => d.path,
      style: {
        textAlign: 'center',
      },
      Cell: ({ column, original }) => (
        <LongTextCell>{column.accessor(original)}</LongTextCell>
      ),
    },
    {
      Header: 'From Path',
      id: 'from',
      accessor: d => d.from,
      width: 380,
      Cell: ({ column, original }) => (
        <LongTextCell>{column.accessor(original) || 'Direct'}</LongTextCell>
      ),
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'At',
      id: 'at',
      width: 150,
      accessor: d => dayjs(d.created_at).format('MM-DD-YY [at] H:mm'),
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Status',
      id: 'status',
      width: 180,
      accessor: ({ status }) =>
        status === 'not-reviewed' ? 'Not Reviewed' : 'Reviewed',
      editAccessor: d => statusOptions.find(({ value }) => value === d.status),
      EditWith: Select,
      editProps: { options: statusOptions },
      style: {
        textAlign: 'center',
      },
    },
    // {
    //   editable: true,
    //   Header: 'Status',
    //   id: 'status',
    //   width: 170,
    //   accessor: ({ status }) =>
    //     status === 'not-reviewed' ? 'Not Reviewed' : 'Reviewed',
    //   editAccessor: d => statusOptions.find(({ value }) => value === d.status),
    //   EditWith: Select,
    //   editProps: {
    //     options: statusOptions,
    //     styles: {
    //       control: provided => ({
    //         ...provided,
    //         width: 140,
    //       }),
    //     },
    //   },
    //   style: {
    //     textAlign: 'center',
    //   },
    // },
  ];
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
  save = async changes => {
    try {
      await apollo.mutate({
        mutation: UpdateNotFoundMutation,
        variables: {
          input: { id: changes.id, notFoundPatch: omit(changes, ['id']) },
        },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  markAllReviewed = async () => {
    this.setState({ markReviewedBtn: 'Updating...' });
    await axios({
      url: `${API_URL}/clearnf`,
      method: 'POST',
      headers: {
        authorization: `Bearer aafebf2cce6f16df695c10b20eef9501cd99753b`,
      },
      data: {},
    });
    this.setState({ markReviewedBtn: 'Done!' });
    this.table.current.table.current.refetch();
    setTimeout(() => {
      this.setState({ markReviewedBtn: 'Mark All Reviewed' });
    }, 500);
  };
  changeFilter = ({ value, filter }) => {
    this.setState({
      filter: filter ? { filter: { ...filter } } : null,
      view: value,
    });
  };
  render() {
    return (
      <>
        <h1>
          404s
          <Select
            className="select"
            width="200px"
            options={viewOptions}
            onChange={this.changeFilter}
            value={viewOptions.find(({ value }) => value === this.state.view)}
          />
          <Button onClick={this.markAllReviewed}>
            {this.state.markReviewedBtn}
          </Button>
        </h1>
        <EditableTable
          ref={this.table}
          queryName="allNotFounds"
          save={this.save}
          query={AllNotFoundsQuery}
          variables={this.state.filter}
          columns={this.columns}
          className="center-cells"
        />
      </>
    );
  }
}
