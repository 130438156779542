import React, { useState } from 'react';
import { set } from 'lodash';
import '../react-table.css';
import Table from '../components/Table';
import Button from '../components/Button';

const editableCell = (cell, { editing }, updateChanges) => {
  const {
    original,
    column: {
      BaseCell,
      EditWith,
      accessor,
      editAccessor,
      editProps,
      updatePath,
      id,
    },
  } = cell;
  if (editing && editing.id === original.id) {
    return (
      <div style={{ width: '100%' }}>
        <EditWith
          defaultValue={
            editAccessor ? editAccessor(original) : accessor(original)
          }
          className="in-table"
          onChange={e =>
            updateChanges(
              {
                updatePath: updatePath || id,
                value: e.hasOwnProperty('value')
                  ? e.value
                  : e.currentTarget.value,
              },
              original.id,
            )
          }
          {...editProps}
        />
      </div>
    );
  }
  return BaseCell ? BaseCell(cell) : <div>{accessor(original)}</div>;
};

export default class EditableTable extends React.Component {
  editableCellFunc = cell => editableCell(cell, this.state, this.updateChanges);
  state = {
    editing: false,
    confirmDelete: false,
  };

  componentDidMount() {
    this.table = React.createRef();
    this.changes = {};
  }
  save = async () => {
    try {
      if (this.changes.hasOwnProperty('id')) {
        await this.props.save(this.changes);
        this.changes = {};
        this.table.current.refetch();
      }
      this.setState({ editing: false, newRow: false });
    } catch (e) {
      alert('Hm, there was an error: ', e.message);
    }
  };
  newRow = row => {
    this.setState({ newRow: { id: 0, ...row }, editing: { id: 0 } });
    setTimeout(() => {
      document.querySelector('.in-table').focus();
    }, 100);
  };
  confirmDelete = original => {
    this.setState({ confirmDelete: original });
    this.delTimo = setTimeout(() => {
      this.setState({ confirmDelete: false });
    }, 800);
  };
  delete = async () => {
    try {
      const { confirmDelete } = { ...this.state };
      this.setState({ confirmDelete: false });
      await this.props.delete(confirmDelete);
      this.table.current.refetch();
    } catch (e) {
      alert('Hm, there was an error: ', e.message);
    }
  };
  updateChanges = ({ updatePath, value }, id) => {
    this.changes = set(this.changes, updatePath, value);
    this.changes.id = id;
  };
  render() {
    const { confirmDelete, newRow, editing } = this.state;
    const columns = [
      ...this.props.columns.map(col =>
        col.editable ? { ...col, Cell: this.editableCellFunc } : col,
      ),
      {
        Header: '',
        id: 'actions',
        Cell: ({ original }) => (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {!(editing && editing.id === original.id) && (
              <Button onClick={() => this.setState({ editing: original })}>
                Edit
              </Button>
            )}
            {!(editing && editing.id === original.id) && (
              <Button
                mode="mild-danger"
                onClick={() =>
                  confirmDelete && confirmDelete.id === original.id
                    ? this.delete()
                    : this.confirmDelete(original)
                }
              >
                {confirmDelete && confirmDelete.id === original.id
                  ? 'Click Again to Delete'
                  : 'Delete'}
              </Button>
            )}
            {editing && editing.id === original.id && (
              <Button onClick={() => this.save()}>Save</Button>
            )}
            {editing && editing.id === original.id && (
              <Button
                mode="mild-danger"
                onClick={() => this.setState({ editing: false, newRow: null })}
              >
                Cancel
              </Button>
            )}
          </div>
        ),
        width: 152,
        style: {
          textAlign: 'center',
        },
      },
    ];
    return (
      <Table
        className={this.props.className}
        newRow={newRow}
        ref={this.table}
        query={this.props.query}
        variables={this.props.variables}
        columns={columns}
        queryName={this.props.queryName}
      />
    );
  }
}
