import axios from 'axios';

// Neworking Setup + Exports
const host = window.location.host;
const development = host.includes('localhost');
export const API_URL = development
  ? 'http://localhost:4400'
  : 'https://api.homealarmreport.com';
export const GRAPH_URL = `${API_URL}/allGraphql`;
axios.defaults.withCredentials = true;
axios.defaults.headers = {
  authorization: `Bearer aafebf2cce6f16df695c10b20eef9501cd99753b`,
};
