import gql from 'graphql-tag';
export default gql`
  query {
    getDataTables {
      edges {
        node {
          tableName
          description
          cols
          numRows
        }
      }
    }
  }
`;
