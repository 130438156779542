import React from 'react';
import { Redirect } from 'react-router-dom';
import '../react-table.css';
import { omit, kebabCase } from 'lodash';
import styled from 'styled-components';
import axios from 'axios';
import Input from '../components/Input';
import Button from '../components/Button';
import { API_URL } from '../constants/Net';
import Loading from '../components/Loading';
import { AuthContext } from '../lib/AuthContextProvider';

const Shell = styled.div`
  width: 800px;
  margin: 6rem auto;
  && h1 {
    margin-bottom: 0rem !important;
  }
  form {
    width: 300px;
  }
  input {
    width: 100%;
    display: block;
    margin-bottom: 1rem;
  }
  button {
    font-size: 0.92rem !important;
    padding: 0.66rem 1rem 0.5rem !important;
    line-height: 120%;
    margin-left: 0;
  }
  .error {
    padding: 18px;
    background: #fff5f4;
    margin-top: 16px;
    color: #a86863;
    font-weight: 600;
    font-size: 15px;
  }
`;
export default class LoginPage extends React.Component {
  static contextType = AuthContext;
  state = {
    status: 'ready',
    email: '',
  };
  componentDidMount() {
    const { params } = this.props.match;
    if (this.context.checked && this.context.isAuthd) {
    }
    if (params.hash) {
      this.context.login(params.hash);
    }
  }
  submit = async e => {
    this.setState({ status: 'sending' });
    e.preventDefault();
    e.stopPropagation();
    const rsp = await axios({
      url: `${API_URL}/sendlogin`,
      method: 'POST',
      data: {
        email: this.state.email,
      },
    });
    if (rsp.data.error) {
      this.context.setError(rsp.data.error);
      this.setState({ status: 'ready' });
    } else {
      this.setState({ status: 'success' });
    }
  };
  render() {
    const { status } = this.state;
    const { params } = this.props.match;
    const { error } = this.context;
    return (
      <AuthContext.Consumer>
        {({ isAuthd, error }) =>
          isAuthd ? (
            <Redirect
              to={{
                pathname: '/',
              }}
            />
          ) : params.hash ? (
            error ? (
              <Shell>
                <h1>Invalid Link</h1>
                <div className="error">{error}</div>
              </Shell>
            ) : (
              <Shell>
                <h1>Logging in...</h1>
                <Loading />
              </Shell>
            )
          ) : (
            <Shell>
              <h1>Login</h1>
              {status === 'success' ? (
                <p>Great, a login link has been sent! Check your email.</p>
              ) : (
                <>
                  <p>
                    Enter your email address below. We'll send you a link to
                    login.
                  </p>
                  <br />
                  <form onSubmit={this.submit}>
                    <Input
                      type="text"
                      name="email"
                      placeholder="Your Email Address"
                      value={this.state.email}
                      onChange={({ currentTarget }) =>
                        this.setState({ email: currentTarget.value })
                      }
                    />
                    <Button>
                      {status === 'ready'
                        ? 'Send Login Link'
                        : status === 'success'
                        ? 'Sent!'
                        : 'Sending...'}
                    </Button>
                    {error && <div className="error">{error}</div>}
                  </form>
                </>
              )}
            </Shell>
          )
        }
      </AuthContext.Consumer>
    );
  }
}
