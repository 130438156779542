import React from 'react';
import '../react-table.css';
import Select from 'react-select';
import styled from 'styled-components';
import Markdown from 'react-remarkable';
import Input from '../components/Input';
import apollo from '../lib/apollo';
import EditableTable from './EditableTable';
import CompanySelector from '../components/CompanySelector';
import { omit } from 'lodash';
import AllReviewsQuery from '../graph/queries/AllReviewsQuery';
import UpdateReviewMutation from '../graph/mutations/UpdateReviewMutation';

const viewOptions = [
  { value: 'all', label: 'Show All', filter: { id: { isNull: false } } },
  {
    value: 'unreview',
    label: 'Unreviewed',
    filter: { publishedAt: { isNull: true }, hiddenAt: { isNull: true } },
  },
  {
    value: 'accepted',
    label: 'Accepted',
    filter: { publishedAt: { isNull: false }, hiddenAt: { isNull: true } },
  },
  {
    value: 'rejected',
    label: 'Rejected',
    filter: { publishedAt: { isNull: true }, hiddenAt: { isNull: false } },
  },
];

const LongTextCell = styled.div`
  width: 100%;
  max-height: 100px;
  overflow: auto;
  white-space: normal;
  text-align: left;
  background: rgba(255, 255, 255, 0.3);
  padding: 14px;
  border: 1px solid #eee;
  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;
const MedTextCell = styled.div`
  width: 100%;
  overflow: none;
  white-space: normal;
  padding: 0;
`;

const statusOptions = [
  { value: 'not-reviewed', label: 'Not Reviewed' },
  { value: 'published', label: 'Published' },
  { value: 'hidden', label: 'Hidden' },
];
const qualityOptions = [
  { value: -5, label: 'Not Helpful' },
  { value: 0, label: 'Normal' },
  { value: 5, label: 'Helpful' },
  { value: 10, label: 'Very Helpful' },
];

export default class PhonePage extends React.Component {
  state = {
    editing: false,
    view: 'all',
  };
  columns = [
    {
      editable: true,
      Header: 'Company',
      id: 'companyId',
      accessor: d => d.companyByCompanyId.name,
      editAccessor: d => d.companyId,
      width: 240,
      EditWith: CompanySelector,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Name',
      id: 'name',
      accessor: d => d.raterName,
      width: 240,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: 'Score',
      id: 'score',
      accessor: d => d.customerServiceScore,
      width: 240,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Title',
      id: 'title',
      accessor: d => d.title,
      BaseCell: ({ column, original }) => (
        <MedTextCell>{column.accessor(original)}</MedTextCell>
      ),
      width: 220,
      EditWith: Input,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Review',
      id: 'body',
      accessor: d => d.body,
      BaseCell: ({ column, original }) => (
        <LongTextCell>
          <Markdown>{column.accessor(original)}</Markdown>
        </LongTextCell>
      ),
      width: 380,
      EditWith: styled.textarea`
        width: 100%;
        border: 1px solid #cccccc;
        border-radius: 4px;
        padding: 4px;
        width: 100%;
        max-height: 100px;
      `,
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Base Quality',
      id: 'baseQuality',
      width: 170,
      accessor: ({ baseQuality }) =>
        qualityOptions.find(({ value }) => +value === +baseQuality).label,
      editAccessor: ({ baseQuality }) =>
        qualityOptions.find(({ value }) => +value === +baseQuality),
      EditWith: Select,
      editProps: {
        options: qualityOptions,
        styles: {
          control: provided => ({
            ...provided,
            width: 140,
          }),
        },
      },
      style: {
        textAlign: 'center',
      },
    },
    {
      editable: true,
      Header: 'Status',
      id: 'status',
      width: 170,
      accessor: ({ hiddenAt, publishedAt }) =>
        hiddenAt ? 'Hidden' : publishedAt ? 'Published' : 'Not Reviewed',
      editAccessor: ({ hiddenAt, publishedAt }) =>
        statusOptions.find(
          ({ value }) =>
            (hiddenAt
              ? 'hidden'
              : publishedAt
              ? 'published'
              : 'not-reviewed') === value,
        ),
      EditWith: Select,
      editProps: {
        options: statusOptions,
        styles: {
          control: provided => ({
            ...provided,
            width: 140,
          }),
        },
      },
      style: {
        textAlign: 'center',
      },
    },
  ];
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }
  save = async changes => {
    try {
      if (changes.status) {
        switch (changes.status) {
          case 'published': {
            changes.hiddenAt = null;
            changes.publishedAt = new Date();
            break;
          }
          case 'hidden': {
            changes.hiddenAt = new Date();
            changes.publishedAt = null;
            break;
          }
          default: {
            changes.hiddenAt = null;
            changes.publishedAt = null;
          }
        }
        changes = omit(changes, ['status']);
      }
      await apollo.mutate({
        mutation: UpdateReviewMutation,
        variables: {
          input: { id: changes.id, reviewPatch: omit(changes, ['id']) },
        },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  changeFilter = ({ value, filter }) => {
    this.setState({
      filter: filter ? { filter: { ...filter } } : null,
      view: value,
    });
  };
  render() {
    return (
      <>
        <h1>
          Reviews
          <Select
            className="select"
            width="200px"
            options={viewOptions}
            value={viewOptions.find(({ value }) => value === this.state.view)}
            onChange={this.changeFilter}
          />
        </h1>
        <EditableTable
          ref={this.table}
          queryName="allReviews"
          save={this.save}
          variables={this.state.filter}
          query={AllReviewsQuery}
          columns={this.columns}
          className="center-cells"
        />
      </>
    );
  }
}
