import React from 'react';
import { Link } from 'react-router-dom';
import '../react-table.css';
import { omit, kebabCase } from 'lodash';
import styled from 'styled-components';
import Input from '../components/Input';
import AllTablesQuery from '../graph/queries/AllTablesQuery';
import apollo from '../lib/apollo';
import UpdatePhoneMutation from '../graph/mutations/UpdatePhoneMutation';
import Button from '../components/Button';
import CreatePhoneMutation from '../graph/mutations/CreatePhoneMutation';
import Table from '../components/Table';
import DeleteTableMutation from '../graph/mutations/DeleteTableMutation';
import CreateTableMutation from '../graph/mutations/CreateTableMutation';

const MedTextCell = styled.div`
  width: 100%;
  white-space: normal;
`;
export default class TablesPage extends React.Component {
  state = {
    confirmDelete: false,
  };
  columns = [
    {
      editable: true,
      Header: 'Name',
      id: 'name',
      accessor: d => d.name,
      width: 260,
      EditWith: Input,
      style: {
        textAlign: 'center',
      },
      Cell: ({ column, original }) => (
        <MedTextCell>{column.accessor(original)}</MedTextCell>
      ),
    },

    {
      editable: true,
      Header: 'Shortcode',
      id: 'slug',
      accessor: d => d.slug || d.id,
      Cell: ({ original, column }) => (
        <input
          type="text"
          value={column.accessor(original)}
          onMouseOver={this.inputOver}
          onMouseOut={this.inputOut}
          style={{
            textAlign: 'center',
            border: '1px solid #dedede',
            padding: '4px',
          }}
        />
      ),
      width: 200,
      EditWith: Input,
      style: {
        textAlign: 'center',
      },
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ original }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'left',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Button as={Link} to={`/table/${original.id}`}>
            Edit
          </Button>
          <Button
            onClick={e => {
              e.preventDefault();
              e.stopPropagation();
              this.duplicate(original);
            }}
          >
            Duplicate
          </Button>
          <Button
            mode="mild-danger"
            onClick={() =>
              this.state.confirmDelete &&
              this.state.confirmDelete.id === original.id
                ? this.delete()
                : this.confirmDelete(original)
            }
          >
            {this.state.confirmDelete &&
            this.state.confirmDelete.id === original.id
              ? 'Click Again to Delete'
              : 'Delete'}
          </Button>
        </div>
      ),
      width: 214,
      style: {
        textAlign: 'center',
      },
    },
  ];
  constructor(props) {
    super(props);
    this.table = React.createRef();
  }

  inputOver = e => {
    e.target.select();
  };
  confirmDelete = original => {
    this.setState({ confirmDelete: original });
    this.delTimo = setTimeout(() => {
      this.setState({ confirmDelete: false });
    }, 800);
  };
  duplicate = async table => {
    const newName = `${table.name} Copy - ${new Date().getMilliseconds()}`;
    try {
      await apollo.mutate({
        mutation: CreateTableMutation,
        variables: {
          input: {
            table: omit({ ...table, name: newName, slug: kebabCase(newName) }, [
              'id',
              '__typename',
            ]),
          },
        },
      });
      this.table.current.refetch();
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  save = async changes => {
    try {
      await apollo.mutate({
        mutation: changes.id === 0 ? CreatePhoneMutation : UpdatePhoneMutation,
        variables:
          changes.id === 0
            ? { input: { phone: omit(changes, ['id']) } }
            : { input: { id: changes.id, phonePatch: omit(changes, ['id']) } },
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  delete = async () => {
    try {
      this.setState({ confirmDelete: false });
      await apollo.mutate({
        mutation: DeleteTableMutation,
        variables: { input: { id: this.state.confirmDelete.id } },
      });
      this.table.current.refetch();
    } catch (e) {
      alert('Hm, there was an error: ', e.message);
    }
  };
  render() {
    return (
      <>
        <h1>
          Tables{' '}
          <Button as={Link} to="/create-table">
            Add Table
          </Button>
        </h1>
        <Table
          ref={this.table}
          queryName="allTables"
          className="center-cells"
          save={this.save}
          query={AllTablesQuery}
          columns={this.columns}
        />
      </>
    );
  }
}
