import React from 'react';
import '../react-table.css';
import apollo from '../lib/apollo';
import { Formik } from 'formik';
import CreateTextblockMutation from '../graph/mutations/CreateTextblockMutation';
import Textarea from '../components/Textarea';
import SpintaxArea from '../components/DynamicContentEditor';
import {
  FormRow,
  Form,
  Input,
  Button,
  Label,
  FormBox,
} from '../components/Form';
import { genRows } from '../components/TableBuilder';

export default class CreateTextblockPage extends React.Component {
  calcTimo = 0;
  state = {
    content: '',
    headline: '',
  };
  submit = async (
    { group, section, content },
    { setSubmitting, setStatus },
  ) => {
    try {
      const rsp = await apollo.mutate({
        mutation: CreateTextblockMutation,
        variables: {
          input: {
            textblock: {
              group,
              section,
              content,
            },
          },
        },
      });
      setSubmitting(false);
      setStatus('success');
      setTimeout(() => {
        this.props.history.push(
          `/textblock/${rsp.data.createTextblock.textblock.id}`,
        );
      });
    } catch (e) {
      alert(`Hm, there was an error: ${e.graphQLErrors[0].message}`);
    }
  };
  calcVals = content => {
    if (content !== this.state.content) {
      clearTimeout(this.calcTimo);
      this.calcTimo = setTimeout(() => {
        this.setState({
          content,
        });
      }, 500);
    }
  };
  render() {
    return (
      <>
        <h1>Create a Textblock</h1>
        <Formik
          initialValues={{
            group: '',
            section: '',
            input: '',
            content: '',
            headline: '',
          }}
          onSubmit={this.submit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            status,
            setFieldValue,
          }) => {
            const { content } = values;
            this.calcVals(content);
            return (
              <Form onSubmit={handleSubmit}>
                <FormRow>
                  <FormBox>
                    <Label>Group</Label>
                    {errors.group && touched.group}
                    <Input
                      type="text"
                      name="group"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.group}
                    />
                  </FormBox>
                  <FormBox>
                    <Label>Section</Label>
                    <Input
                      type="text"
                      name="section"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.section}
                    />
                  </FormBox>
                </FormRow>
                <FormRow>
                  <FormBox>
                    <Label>Headline</Label>
                    <SpintaxArea
                      Component={Input}
                      name="headline"
                      value={values.headline}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormBox>
                </FormRow>
                <FormRow>
                  <FormBox maxWidth="100%">
                    <Label>Content</Label>
                    <SpintaxArea
                      Component={Textarea}
                      style={{ width: '100%', minHeight: '180px' }}
                      name="content"
                      value={values.content}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormBox>
                </FormRow>
                <FormRow>
                  <FormBox>
                    <Button type="submit" disabled={isSubmitting}>
                      {isSubmitting
                        ? 'Creating...'
                        : status === 'success'
                        ? 'Created!'
                        : 'Create'}
                    </Button>
                  </FormBox>
                </FormRow>
              </Form>
            );
          }}
        </Formik>
      </>
    );
  }
}
