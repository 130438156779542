import React from 'react';
import axios from 'axios';
import { API_URL } from '../constants/Net';

/*
 * React Context Provider that holds current user state
 * + authentication related functions
 */
export const AuthContext = React.createContext({
  isAuthd: false,
  checked: false,
  error: false,
  user: false,
  authenticate: () => {},
  login: () => {},
  logout: () => {},
});
export class AuthContextProvider extends React.PureComponent {
  state = {
    isAuthd: false,
    error: false,
    checked: false,
  };
  async componentDidMount() {
    this.authenticate();
  }
  async authenticate() {
    const rsp = await axios({
      url: `${API_URL}/me`,
      method: 'GET',
    });
    if (rsp.data.user) {
      this.setAuth(rsp.data.user);
    } else {
      this.setState({ checked: true, isAuthd: false });
    }
  }
  async logout() {
    this.setState({ isAuthd: false });
    this.isAuthenticated = false;
    const rsp = await axios({
      url: `${API_URL}/logout`,
      method: 'POST',
    });
  }
  login = async hash => {
    const rsp = await axios({
      url: `${API_URL}/login`,
      method: 'POST',
      data: {
        hash,
      },
    });
    if (rsp.data.user) {
      this.setAuth(rsp.data.user);
    } else {
      this.setState({ isAuthd: false, error: 'Login link not valid' });
    }
  };
  setAuth(user) {
    this.setState({ checked: true, user, isAuthd: true });
  }
  setError = error => {
    this.setState({ error });
  };
  render() {
    return (
      <AuthContext.Provider
        value={{
          isAuthd: this.state.isAuthd,
          authenticate: this.authenticate,
          login: this.login,
          logout: this.logout,
          checked: this.state.checked,
          error: this.state.error,
          setError: this.setError,
          user: this.state.user,
        }}
      >
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}
