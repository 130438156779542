import styled from 'styled-components';

export default styled.textarea`
  padding: 0px 10px 0 10px;
  border: 1px solid #ddd;
  font-family: 'Assistant';

  &.in-table {
    font-size: 1rem;
    width: 100%;
    padding: 6px;
    width: 100%;
    padding: 6px 7px 6px;
    border-radius: 4px;
    border-color: #cbcbcb;
    font-size: 1.1rem;
  }
`;
