import { createGlobalStyle } from 'styled-components';
import { media } from '../constants/Style';

export const GlobalStyles = createGlobalStyle`
  html {
    ${media.lap`
      font-size: 16px;
    `}
    ${media.lapsm`
      font-size: 14.3px;
    `}
  }
  body {
    font-family: 'Assistant', sans-serif;
  }
  * {
    box-sizing: border-box;
  }
  .center-cells .rt-td {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  body h1 {
    button, a {
      display: inline-block;
      font-size: 0.92rem !important;
      padding: 0.66rem 1rem 0.5rem !important;
      line-height: 120%;
      top: -1px !important;
      position: relative;
    }
  }
`;
