import styled from 'styled-components';
import { Colors } from '../constants/Style';
import { lighten } from 'polished';

export default styled.button`
  display: inline;
  border: 1px solid #ced7da;
  border-radius: 3px;
  margin-left: 4px;
  background: #fff;
  text-decoration: none;
  padding: 4px 10px 3px;
  margin-bottom: -2px;
  position: relative;
  color: ${({ mode }) =>
    mode === 'mild-danger' ? Colors.mildRed : Colors.darkBlueGray};
  font-size: 13px;
  cursor: pointer;
  transition: 0.24s all;
  font-weight: 600;
  font-family: sans-serif;
  box-shadow: 1px 1px 1px rgba(206, 215, 218, 0.55);
  &:hover {
    background: ${({ mode }) =>
      mode === 'mild-danger'
        ? lighten(0.44, Colors.mildRed)
        : Colors.whiteBlue};
  }
  &.small {
    padding: 2px 5px 1px;
  }
`;
