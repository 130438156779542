import React from 'react';
import { Query } from 'react-apollo';
import ReactTable from 'react-table';
import '../react-table.css';
import { flatten } from 'lodash';
import styled from 'styled-components';
import Select from 'react-select';
import unpackEdge from '../lib/unpackEdge';

const LongTextCell = styled.div`
  width: 100%;
  max-height: 100px;
  overflow: auto;
  white-space: normal;
  text-align: left;
  background: rgba(255, 255, 255, 0.3);
  padding: 14px;
  border: 1px solid #eee;
  p {
    &:first-of-type {
      margin-top: 0;
    }
  }
`;
const MedTextCell = styled.div`
  width: 100%;
  overflow: none;
  white-space: normal;
  padding: 0;
`;

export default class Table extends React.Component {
  state = {
    sorted: [],
    page: 0,
    pageSize: 100,
    resized: [],
    filtered: [],
    editing: false,
    show: 'all',
  };

  render() {
    const { state } = this;
    const { show } = state;
    const { bySlug } = this.props;
    const teams = ['all'];
    const slugs = ['all'];
    let data = flatten(
      Object.entries(bySlug).map(([slug, val]) =>
        val.map(row => {
          if (!teams.includes(row.team)) {
            teams.push(row.team);
          }
          if (!slugs.includes(slug)) {
            slugs.push(slug);
          }
          return {
            ...row,
            slug,
          };
        }),
      ),
    );
    const teamOptions = teams.map(team => ({ value: team, label: team }));
    const slugOptions = slugs.map(slug => ({ value: slug, label: slug }));

    const columns = [
      {
        Header: 'Page Slug',
        id: 'slug',
        accessor: d => d.slug,
        width: 340,
        Cell: ({ column, original }) => (
          <MedTextCell>{column.accessor(original)}</MedTextCell>
        ),
        Filter: ({ filter, onChange }) => (
          <Select
            onChange={event => onChange(event.value)}
            style={{ width: '100%' }}
            value={
              filter
                ? slugOptions.filter(({ value }) => filter.value === value)
                : 'all'
            }
            options={slugOptions}
          />
        ),
      },
      {
        Header: 'Priority',
        id: 'priority',
        accessor: d => d.priority,
        width: 200,
        style: {
          textAlign: 'center',
        },
        Filter: () => <div />,
      },
      {
        Header: 'Team',
        id: 'team',
        accessor: d => d.team,
        width: 200,
        style: {
          textAlign: 'center',
        },
        Filter: ({ filter, onChange }) => (
          <Select
            onChange={event => onChange(event.value)}
            style={{ width: '100%' }}
            value={
              filter
                ? teamOptions.filter(({ value }) => filter.value === value)
                : 'all'
            }
            options={teamOptions}
          />
        ),
      },
      {
        Header: 'Message',
        id: 'message',
        accessor: d => d.message,
        Cell: ({ column, original }) => (
          <LongTextCell>{column.accessor(original)}</LongTextCell>
        ),
        Filter: () => <div />,
      },
    ];
    return (
      <ReactTable
        data={data}
        columns={columns}
        defaultPageSize={100}
        pageSizeOptions={[5, 10, 20, 25, 50, 100]}
        className={`-striped -highlight center-cells`}
        // Controlled props
        sorted={state.sorted}
        filterable={true}
        page={state.page}
        pageSize={state.pageSize}
        resized={state.resized}
        filtered={state.filtered}
        // Callbacks
        onSortedChange={sorted => this.setState({ sorted })}
        onPageChange={page => this.setState({ page })}
        onPageSizeChange={(pageSize, page) => this.setState({ page, pageSize })}
        onExpandedChange={expanded => this.setState({ expanded })}
        onResizedChange={resized => this.setState({ resized })}
        onFilteredChange={filtered => this.setState({ filtered })}
      />
    );
  }
}
