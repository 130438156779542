import gql from 'graphql-tag';
export default gql`
  query TextblockById($id: Int!) {
    textblockById(id: $id) {
      id
      section
      group
      content
      context
      createdAt
      headline
      updatedAt
    }
  }
`;
