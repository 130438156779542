import gql from 'graphql-tag';
export default gql`
  query {
    allTables {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          name
          slug
          json
          createdAt
          updatedAt
        }
      }
    }
  }
`;
