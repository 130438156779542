import { get } from 'lodash';

/*
 * Allows easier access to GraphQL response edges
 */
export default (data, edge) => {
  if (get(data, edge)) {
    return get(data, edge).edges.map(({ node }) => node);
  }
  return [];
};
