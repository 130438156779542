import gql from 'graphql-tag';
export default gql`
  query {
    allLinks {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          destination
          companyId
          createdAt
          name
          active
          companyByCompanyId {
            name
            slug
            id
          }
        }
      }
    }
  }
`;
